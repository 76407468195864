import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AudioService, deepClone } from 'lib';
import { ApplyPurchaseOrderApprovalInput, InvestorPaymentTypeEnum, InvestorPurchaseOrderInfoInput, Mission, Mutation, PurchaseOrder, PurchasePaymentTypeEnum, Query, ReceivePurchaseOrderInput, ShippingOrder, ShippingPaymentTypeEnum, Subscription, UploadPurchaseOrderFileInput } from 'lib/src/types/schema';
import { BehaviorSubject, Subject, combineLatest, merge } from 'rxjs';
import { bufferCount, filter, map, startWith, take } from 'rxjs/operators';
import { APPLY_PURCHASE_ORDER_APPROVAL_MUTATION, MISSIONS_QUERY, PURCHASE_ORDERS_QUERY, PURCHASE_ORDER_INFO_QUERY, RECEIVE_BUY_ORDER_MUTATION, SHIPPING_ORDERS_QUERY, UPLOAD_PURCHASE_ORDER_FILE_MUTATION } from '../helpers/api';
import { OrderSettingService } from './order-setting.service';

type OrderCountByPayment = { [key: string]: number };

export interface MissionExt extends Mission {
  count?: number;
}

@Injectable({
  providedIn: 'root'
})
export class MissionService {
  missions = new BehaviorSubject<Mission[]>([]);
  orderCountByPaymentType: OrderCountByPayment;
  shippingOrders = new BehaviorSubject<ShippingOrder[]>([]);
  filterShippingOrders = new BehaviorSubject<ShippingOrder[]>([]);
  shippingOrdersCount: number | string;
  isShowShippingOrdersBadge: boolean = false;
  newShippingOrder = new Subject<boolean>();
  purchaseOrders = new BehaviorSubject<PurchaseOrder[]>([]);
  filterPurchaseOrders = new BehaviorSubject<PurchaseOrder[]>([]);
  purchaseOrdersCount: number | string;
  isShowPurchaseOrdersBadge: boolean = false;
  newPurchaseOrder = new Subject<boolean>();
  loading = new BehaviorSubject<boolean>(true);
  private orderLoading = new BehaviorSubject<boolean>(true);
  private missionLoading = new BehaviorSubject<boolean>(true);
  private missionsQuery = this.apollo.watchQuery<Query>({
    query: MISSIONS_QUERY
  });
  public shippingOrdersQuery = this.apollo.watchQuery<Query>({
    query: SHIPPING_ORDERS_QUERY,
    fetchPolicy: 'no-cache',
    pollInterval: 15000
  });
  public purchaseOrdersQuery = this.apollo.watchQuery<Query>({
    query: PURCHASE_ORDERS_QUERY,
    fetchPolicy: 'no-cache',
    pollInterval: 15000
  });

  constructor(
    private apollo: Apollo,
    private audio: AudioService,
    public orderSettingSer: OrderSettingService,
  ) {
    this.init();
    this.getMissions();
    this.pollingShippingOrders();
    this.pollingPurchaseOrders();
    this.calcMissionCount();
    // mission跟order讀取完成後將loading設成false
    combineLatest([this.missionLoading, this.orderLoading]).pipe(
      map(([missionLoading, orderLoading]) => missionLoading === false && orderLoading === false),
      filter(loading => loading === true),
      take(1)
    ).subscribe(loading => this.loading.next(false));
  }

  init() {
    // 出貨
    merge(
      this.shippingOrders,
      this.orderSettingSer.investorShippingPaymentTypes,
    ).subscribe((resp) => {
      this.pollingFilterShippingOrders();
    });
    // 進貨
    merge(
      this.purchaseOrders,
      this.orderSettingSer.investorPurchasePaymentTypes,
    ).subscribe((resp) => {
      this.pollingFilterPurchaseOrders();
    })
  }

  receivePurchaseOrderMutation(input: ReceivePurchaseOrderInput) {
    return this.apollo.mutate<Mutation>({
      mutation: RECEIVE_BUY_ORDER_MUTATION,
      variables: { input }
    })
  }

  purchaseOrderInfoQuery(input: InvestorPurchaseOrderInfoInput) {
    return this.apollo.watchQuery<Query>({
      query: PURCHASE_ORDER_INFO_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })
  }

  uploadPurchaseOrderFile(input: UploadPurchaseOrderFileInput) {
    return this.apollo.mutate<Mutation>({
      mutation: UPLOAD_PURCHASE_ORDER_FILE_MUTATION,
      variables: { input },
      context: {
        useMultipart: true
      }
    })
  }

  applyPurchaseOrderApproval(input: ApplyPurchaseOrderApprovalInput) {
    return this.apollo.mutate<Mutation>({
      mutation: APPLY_PURCHASE_ORDER_APPROVAL_MUTATION,
      variables: { input },
    })
  }

  refetchShippingSetting() {
    return this.shippingOrdersQuery.refetch();
  }

  refetchPurchaseSetting() {
    return this.purchaseOrdersQuery.refetch();
  }

  isShowBadge(count: number) {
    return count > 0; // 計數大餘 0 前台顯示 Badge
  }

  countDisplay(count: number) {
    if (count > 9) return '9+'; // 計數大餘 9 顯示 9+
    return count;
  }

  pollingShippingOrders(): void {
    this.shippingOrdersQuery.valueChanges.pipe(
      startWith(null),
      bufferCount(2, 1)
    ).subscribe(([prevResp, currResp]) => {
      this.orderLoading.next(false);
      const prevShippingOrders = prevResp?.data.me?.shippingOrders!;
      const currShippingOrders = currResp?.data.me?.shippingOrders!;
      this.shippingOrders.next(currShippingOrders!);
      // 有接到新單就喊有單有單
      if (this.hasNewShippingOrder(prevShippingOrders, currShippingOrders)) {
        this.newShippingOrder.next(true);
        this.audio.playAudio('/assets/media/new_order_sound.mp3');
      }
    });
    this.shippingOrdersQuery.subscribeToMore<Subscription>({
      document: gql`
      subscription {
        shippingOrderCreated {
          _id
          amount
          paymentType
          commodity {
            id
            name
            price
            image
          }
          shippingFee
          investorId
          status
          createdAt
          investorBonus
          exchangeRate
          orderAmountInUsdt
          payerName
          payeeBankId
          payeeBankAccountName
          payeeBankAccount
          payeeBankBranchName
        }
      }`,
      // variables: {investorId: 1},
      updateQuery: (prev, { subscriptionData }) => {
        console.log(subscriptionData)
        let newResult = deepClone(prev);
        let newShippingOrder = subscriptionData.data.shippingOrderCreated!;
        newResult.me!.shippingOrders = [
          ...(newResult.me?.shippingOrders ? newResult.me?.shippingOrders : []),
          newShippingOrder
        ].filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i); // 移除重複
        return newResult;
      }
    })
  }

  private getShippingPaymentType(item: InvestorPaymentTypeEnum) {
    if (item === InvestorPaymentTypeEnum.BankAccount) return ShippingPaymentTypeEnum.NetEase;
    if (item === InvestorPaymentTypeEnum.Alipay) return ShippingPaymentTypeEnum.AlipayH5;
    if (item === InvestorPaymentTypeEnum.AlipayScan) return ShippingPaymentTypeEnum.AlipayScan;
    if (item === InvestorPaymentTypeEnum.WechatScan) return ShippingPaymentTypeEnum.WechatScan;
    return ShippingPaymentTypeEnum.NetEase;
  }

  private filterShippingOrdersData(currShippingOrders: ShippingOrder[], paymentType: ShippingPaymentTypeEnum[]) {
    return currShippingOrders.filter(item => { return paymentType.includes(item.paymentType) });
  }

  pollingFilterShippingOrders(): void {
    let shippingOrders: ShippingOrder[];
    const activePaymentTypes = this.orderSettingSer.investorShippingPaymentTypes.value;
    // 判断市场的选取，若有就过滤
    let paymentType: ShippingPaymentTypeEnum[] = [];
    if (activePaymentTypes.length > 0) {
      activePaymentTypes.forEach(item => { paymentType.push(this.getShippingPaymentType(item)) });
      shippingOrders = this.filterShippingOrdersData(this.shippingOrders.value, paymentType);
    } else {
      shippingOrders = this.shippingOrders.value;
    }
    this.shippingOrdersCount = this.countDisplay(shippingOrders?.length!); // 計數大餘 9 時顯示為 9+
    this.isShowShippingOrdersBadge = this.isShowBadge(shippingOrders?.length!); // 計數大餘 9 顯示 9+
    this.filterShippingOrders.next(shippingOrders);
  }

  pollingPurchaseOrders(): void {
    this.purchaseOrdersQuery.valueChanges.pipe(
      startWith(null),
      bufferCount(2, 1)
    ).subscribe(([prevResp, currResp]) => {
      this.orderLoading.next(false);
      const prevPurchaseOrders = prevResp?.data.me?.purchaseOrders!;
      const currPurchaseOrders = currResp?.data.me?.purchaseOrders!;
      this.purchaseOrders.next(currPurchaseOrders!);
      // 有接到新單就喊有單有單
      if (this.hasNewPurchaseOrder(prevPurchaseOrders, currPurchaseOrders)) {
        this.newPurchaseOrder.next(true);
        this.audio.playAudio('/assets/media/new_order_sound.mp3');
      }
    });
    this.purchaseOrdersQuery.subscribeToMore<Subscription>({
      document: gql`
      subscription {
        purchaseOrderCreated {
          _id
          amount
          paymentType
          investorId
          status
          commodity {
            id
            name
            price
            image
          }
          shippingFee
          createdAt
          investorBonus
          exchangeRate
          orderAmountInUsdt
          payerBankId
          payerBankAccountName
          payerBankAccount
          payerBankBranchName
        }
      }`,
      // variables: {investorId: 1},
      updateQuery: (prev, { subscriptionData }) => {
        console.log(subscriptionData)
        let newResult = deepClone(prev);
        let newPurchaseOrder = subscriptionData.data.purchaseOrderCreated!;
        newResult.me!.purchaseOrders = [
          ...(newResult.me?.purchaseOrders ? newResult.me?.purchaseOrders : []),
          newPurchaseOrder
        ].filter((v, i, a) => a.findIndex(t => (t._id === v._id)) === i); // 移除重複
        return newResult;
      }
    })
  }

  private getPurchasePaymentType(item: InvestorPaymentTypeEnum) {
    if (item === InvestorPaymentTypeEnum.BankAccount) return PurchasePaymentTypeEnum.Yoho;
    if (item === InvestorPaymentTypeEnum.Alipay) return PurchasePaymentTypeEnum.AlipayH5Purchase;
    return PurchasePaymentTypeEnum.Yoho;
  }

  private filterPurchaseOrdersData(currPurchaseOrders: PurchaseOrder[], paymentType: PurchasePaymentTypeEnum[]) {
    return currPurchaseOrders.filter(item => { return paymentType.includes(item.paymentType) });
  }

  pollingFilterPurchaseOrders(): void {
    let purchaseOrders: PurchaseOrder[];
    const activePaymentTypes = this.orderSettingSer.investorPurchasePaymentTypes.value;
    // 判断市场的选取，若有就过滤
    let paymentType: PurchasePaymentTypeEnum[] = [];
    if (activePaymentTypes.length > 0) {
      activePaymentTypes.forEach(item => { paymentType.push(this.getPurchasePaymentType(item)) });
      purchaseOrders = this.filterPurchaseOrdersData(this.purchaseOrders.value, paymentType);
    } else {
      purchaseOrders = this.purchaseOrders.value;
    }
    this.purchaseOrdersCount = this.countDisplay(purchaseOrders?.length!); // 計數大餘 9 時顯示為 9+
    this.isShowPurchaseOrdersBadge = this.isShowBadge(purchaseOrders?.length!); // 計數大餘 9 顯示 9+
    this.filterPurchaseOrders.next(purchaseOrders);
  }

  private hasNewShippingOrder(prevSOs: ShippingOrder[], currSOs: ShippingOrder[]): boolean {
    // 在新的“已分配”SO中找不到任何一筆與前一次SO有重複，代表是新的資料
    return currSOs.filter(cSO => cSO.investorId).some(cSO => {
      return !prevSOs?.some(prevSO => prevSO._id === cSO._id);
    })
  }

  private hasNewPurchaseOrder(prevPOs: PurchaseOrder[], currPOs: PurchaseOrder[]): boolean {
    // 在新的“已分配”PO中找不到任何一筆與前一次“已分配”的PO有重複，代表是新的資料
    return currPOs.filter(cPO => cPO.investorId).some(cPO => {
      return !prevPOs?.some(prevPO => prevPO._id === cPO._id && prevPO.investorId);
    })
  }

  private getMissions() {
    this.missionsQuery.valueChanges.subscribe(resp => {
      this.missionLoading.next(false);
      this.missions.next(resp.data.missions);
    })
  }

  // 計算各 mission 的 order 數量
  private calcMissionCount() {
    combineLatest([this.missions, this.purchaseOrders, this.shippingOrders]).pipe(
      filter(([missions]) => missions.length != 0)
    ).subscribe(([missions, purchaseOrders, shippingOrders]) => {
      this.orderCountByPaymentType = missions.reduce((result: any, mission) => {
        const paymentType = (mission.shippingPaymentType || mission.purchasePaymentType)!;
        if (result[paymentType] == null) {
          result[paymentType] = 0;
        }
        purchaseOrders.forEach(po => po.paymentType === paymentType && result[paymentType]++)
        shippingOrders.forEach(po => po.paymentType === paymentType && result[paymentType]++)
        return result;
      }, {});
    });
  }
}
