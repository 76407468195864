import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { DeviceService, F2eHelper, MessageService, ModalService, isCompressionImageNotContainIOSQuark } from 'lib';
import { ConfirmShippingOrderInput, EnumItem, InvestorOrderInfo_ShippingOrderInfoArgs, InvestorShippingOrderInfo, Maybe, Query, ReportShippingOrderProblemInput, ShippingOrder, ShippingOrderProblemTypeEnum, ShippingPaymentTypeEnum } from 'lib/src/types/schema';
import { CONFIRM_SHIPPING_ORDER_MUTATION, REPORT_SHIPPING_ORDER_PROBLEM_MUTATION, SHIPPING_ORDER_PROBLEM_TYPE_QUERY, SHIPPING_ORDER_QUERY } from '../../helpers/api';
import { InvestorConfigService } from '../../services/investor-config.service';
import { LanguageService } from '../../services/language.service';
import { MissionService } from '../../services/mission.service';
import { OrderSettingService } from '../../services/order-setting.service';
import { PaymentService } from '../../services/payment.service';
import { ShippingOrderReportModalComponent } from '../shipping-order-report-modal/shipping-order-report-modal.component';

enum OrderStatus {
  /** 处理中 */
  PROCESSING = '处理中',
  /** 待确认收款 */
  CONFIRMING = '待确认收款',
  /** 成功 */
  SUCCESS = '成功',
  /** 失败 */
  FAILURE = '失败',
  /** 待审核 */
  WAIT_FOR_APPROVAL = '待审核'
}

@Component({
  selector: 'kr-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit {
  shippingOrders: ShippingOrder[] = [];
  orderStatus = OrderStatus;
  isOrderDetailVisible = false
  loading = true;
  selectedOrder: ShippingOrder;
  orderInfo: InvestorShippingOrderInfo;
  form: FormGroup = this.formBuilder.group({
    amount: ['', [Validators.required]],
    name: ['', [Validators.required]],
  });

  showReportOrder = false;
  previewImage: SafeUrl;
  isUploading = false;
  problems?: EnumItem[];
  problem: ShippingOrderProblemTypeEnum;
  problemRemark: string;
  problemImage: File | Blob;
  detailPayeeBank?: Maybe<string>;
  detailPayeeName?: Maybe<string>;
  isShowPayeeBank: boolean = true;
  isShowNameInput: boolean = true;
  isAlipayShow: boolean = false;
  isAlipayScanShow: boolean = false;
  isWechatScanShow: boolean = false;

  constructor(
    public missionSer: MissionService,
    public paymentSer: PaymentService,
    public lang: LanguageService,
    public orderSettingSer: OrderSettingService,
    private apollo: Apollo,
    private clipboard: Clipboard,
    private messageSer: MessageService,
    private deviceSer: DeviceService,
    private modalSer: ModalService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private investorConfigSer: InvestorConfigService,
  ) { }

  ngOnInit(): void {
    this.getShippingOrders();
  }

  getShippingOrders() {
    this.missionSer.filterShippingOrders.subscribe(orders => {
      this.shippingOrders = orders;
      this.loading = false;
    })
  }

  private async getOrder() {
    this.detailPayeeBank = '';
    this.detailPayeeName = '';
    const variables: InvestorOrderInfo_ShippingOrderInfoArgs = {
      input: {
        orderUuid: this.selectedOrder._id
      }
    }
    const resp = await this.apollo.query<Query>({ query: SHIPPING_ORDER_QUERY, variables, fetchPolicy: 'no-cache' }).toPromise();
    if (resp.data) {
      this.orderInfo = resp.data.me?.orderInfo?.shippingOrderInfo!;
      if (this.orderInfo.payeePaymentAccount && this.orderInfo.paymentType === ShippingPaymentTypeEnum.NetEase) {
        const payeeBank = this.paymentSer.bankMap$.value[this.orderInfo?.payeePaymentAccount?.bankId || '-']?.name || '';
        const payeeBranch = this.orderInfo?.payeePaymentAccount?.bankAccount?.slice(-4);
        this.detailPayeeBank = `${payeeBank} (${payeeBranch})`;
        this.detailPayeeName = this.orderInfo?.payeePaymentAccount?.bankAccountName;
        this.isShowPayeeBank = true;
        this.isShowNameInput = true;
        this.isAlipayShow = false;
        this.isAlipayScanShow = false;
        this.isWechatScanShow = false;
      } else if (this.orderInfo.payeeAlipayPaymentAccount && this.orderInfo.paymentType === ShippingPaymentTypeEnum.AlipayH5) {
        this.detailPayeeBank = this.orderInfo.payeeAlipayPaymentAccount.alipayAccount;
        this.detailPayeeName = this.orderInfo.payeeAlipayPaymentAccount.alipayName;
        this.isShowPayeeBank = false;
        this.isShowNameInput = false;
        this.isAlipayShow = true;
        this.isAlipayScanShow = false;
        this.isWechatScanShow = false;
      } else if (this.orderInfo.payeeAlipayScanAccount && this.orderInfo.paymentType === ShippingPaymentTypeEnum.AlipayScan) {
        this.detailPayeeBank = this.orderInfo.payeeAlipayScanAccount.alipayUid;
        this.detailPayeeName = this.orderInfo.payeeAlipayScanAccount.alipayName;
        this.isShowPayeeBank = false;
        this.isShowNameInput = false;
        this.isAlipayShow = false;
        this.isAlipayScanShow = true;
        this.isWechatScanShow = false;
      } else if (this.orderInfo.payeeWechatScanAccount && this.orderInfo.paymentType === ShippingPaymentTypeEnum.WechatScan) {
        this.detailPayeeName = this.orderInfo.payeeWechatScanAccount.wechatName;
        this.isShowPayeeBank = false;
        this.isShowNameInput = false;
        this.isAlipayShow = false;
        this.isAlipayScanShow = false;
        this.isWechatScanShow = true;
      }
    }
  }

  market(data: ShippingOrder): string {
    switch (data.paymentType) {
      case ShippingPaymentTypeEnum.NetEase:
        return '银行卡';
      case ShippingPaymentTypeEnum.AlipayH5:
        return '支付宝';
      case ShippingPaymentTypeEnum.AlipayScan:
        return '支付宝扫码';
      case ShippingPaymentTypeEnum.WechatScan:
        return '微信扫码';
      default:
        return '';
    }
  }

  replaceSecondCharWithX(inputString: string): string {
    if (inputString.length >= 2) {
      return inputString.replace(/^(.)(.)/, `$1x`);
    }
    return inputString;
  }

  async showDetailModal(order: ShippingOrder) {
    this.loading = true;
    this.selectedOrder = order;
    await this.getOrder();
    this.loading = false;
    this.isOrderDetailVisible = true;
    const resp = await this.apollo.query<Query>({ query: SHIPPING_ORDER_PROBLEM_TYPE_QUERY, fetchPolicy: 'no-cache' }).toPromise();
    if (resp.data) {
      this.problems = resp.data.enums?.shippingOrderProblemType;
    }
  }

  no() {
    this.showReportOrder = false;
    this.isOrderDetailVisible = false;
    this.resetForm();
    this.resetReportForm();
  }

  async yes() {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    switch (this.orderInfo.paymentType) {
      case ShippingPaymentTypeEnum.NetEase:
        if (this.form.invalid) return;
        break;
      case ShippingPaymentTypeEnum.AlipayH5:
      case ShippingPaymentTypeEnum.AlipayScan:
      case ShippingPaymentTypeEnum.WechatScan:
        if (this.form.controls['amount'].invalid) return;
        this.form.controls['name'].setValue('');
        break;
    }
    const smsStr = await this.deviceSer.getSMS(Math.floor(Date.parse(this.selectedOrder.createdAt) / 1000));
    let input: ConfirmShippingOrderInput = {
      orderUuid: this.selectedOrder._id,
      name: this.form.controls.name.value,
      amount: Number(this.form.controls.amount.value),
      mobileMessage: smsStr
    };
    this.apollo.mutate({
      mutation: CONFIRM_SHIPPING_ORDER_MUTATION,
      variables: { input }
    }).subscribe(resp => {
      this.messageSer.notify('已确认收款')
      this.isOrderDetailVisible = false;
      this.missionSer.shippingOrdersQuery.refetch();
      this.resetForm();
    });
  }

  resetForm(): void {
    this.form.reset();
    for (const key in this.form.controls) {
      this.form.controls[key].markAsPristine();
      this.form.controls[key].updateValueAndValidity();
    }
  }

  resetReportForm(): void {
    this.problem = ShippingOrderProblemTypeEnum.None;
    this.problemRemark = '';
    this.previewImage = '';
  }

  copy(text: string) {
    this.clipboard.copy(text);
    this.messageSer.notify('已複製', 600);
  }

  async chooseFile(e: any) {
    if (isCompressionImageNotContainIOSQuark(this.investorConfigSer.config?.value?.isCompressImageBeforeUpload!)) {
      const compressionImage: Blob = await F2eHelper.compressionUploadImage(e.target.files[0]);
      if (compressionImage) this.problemImage = compressionImage;
    } else {
      this.problemImage = e.target.files[0];
    }

    this.previewImage = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(e.target.files[0])
    );
  }

  resetFile(e: any) {
    if (e) {
      e.target.value = '';
    }
  }

  showReportOrderConfirm() {
    this.messageSer.confirm({
      title: '温馨提示',
      text: '若金额错误、打款人错误、支付方式错误、或长时间未收到款项，请上传相关文件，以便客服人员及时处理',
      onYes: () => {
        this.showReportOrder = true;
      }
    })
  }

  report() {
    this.modalSer.open(ShippingOrderReportModalComponent, {}, c => { })
  }


  reportProblem() {
    const input: ReportShippingOrderProblemInput = {
      orderUuid: this.selectedOrder._id,
      problemType: this.problem,
      files: [this.problemImage],
      remark: this.problemRemark
    }
    if (this.problem == null || this.problemImage == null) {
      this.messageSer.notify('请填写完整');
      return;
    }
    this.isUploading = true;
    this.apollo.mutate({
      mutation: REPORT_SHIPPING_ORDER_PROBLEM_MUTATION,
      variables: { input },
      context: {
        useMultipart: true
      }
    }).subscribe({
      next: resp => {
        this.isUploading = false;
        this.messageSer.notify({ text: '异常订单回报/上传成功，审核中', type: 'success' });
        this.missionSer.shippingOrdersQuery.refetch();
        this.resetReportForm();
        this.isOrderDetailVisible = false;
      },
      error: error => {
        this.isUploading = false;
        console.log(error)
      }
    });
  }
}
