import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AudioService, ModalService } from 'lib';
import { InvestorMessage, InvestorMessageTypeEnum, Subscription } from 'lib/src/types/schema';
import { IndividualConfig } from 'ngx-toastr';
import { ShippingOrder } from 'projects/lib/src/types/schema';
import { NEW_MESSAGE_SUBSCRIPTION } from '../helpers/api';
import { OrderSettingService } from './order-setting.service';
import { MissionService } from './mission.service';
import { PurchaseOrderInfoModalComponent } from '../component/purchase-order-info-modal/purchase-order-info-modal.component';
import { ShippingOrderInfoModalComponent } from '../component/shipping-order-info-modal/shipping-order-info-modal.component';
import { MessageCode, NotificationTempService } from './notification-temp.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(
    private apollo: Apollo,
    private modalSer: ModalService,
    private router: Router,
    private audioSer: AudioService,
    private orderSettingSer: OrderSettingService,
    private missionSer: MissionService,
    private notificationTempService: NotificationTempService,
  ) {
    this.apollo.subscribe<Subscription>({
      query: NEW_MESSAGE_SUBSCRIPTION,
    }).subscribe(resp => {
      const newMsg = resp.data?.investorMessageCreated;
      if (newMsg) {
        this.newMessageHandler(newMsg);
      }
    })
  }

  private newMessageHandler(message: InvestorMessage) {
    console.log('Notification Message!', message);
    this.playMessageSound(message);
    this.executeMessageAction(message);
    // const toastConfig = this.toastConfig(message);
    const duration: number = 15000; // 持续时间(毫秒)，当设置为0时不消失
    const data = { title: message.title, message: this.formatMessage(message) };
    this.notificationTempService.showMessage(MessageCode.Notification, data, duration).onClose.subscribe(() => {
      console.log('Notification Closed!');
      this.onTapAction(message);
    });
  }

  private toastConfig(message: InvestorMessage): Partial<IndividualConfig> {
    switch (message.type) {
      case InvestorMessageTypeEnum.AutomaticDisableInvestorShipping:
      case InvestorMessageTypeEnum.BlockedPaymentBankAccount:
      case InvestorMessageTypeEnum.BlockedWithdrawBankAccount:
      case InvestorMessageTypeEnum.DisableShippingBecauseQuota:
      case InvestorMessageTypeEnum.ShippingOrderGot:
      case InvestorMessageTypeEnum.PurchaseOrderGot:
        // 关闭出货通知不要自动消失
        return {
          disableTimeOut: true
        };
      default:
        return {};
    }
  }

  formatMessage(message: InvestorMessage): string {
    const extraInfo = this.toObject(message);
    switch (message.type) {
      case InvestorMessageTypeEnum.ShippingOrderConfirmed:
        return `${extraInfo.orderUuid}，金额：${extraInfo.orderAmount}，${message.message}`;
      case InvestorMessageTypeEnum.PurchaseOrderConfirmed:
        return `${extraInfo.orderUuid}，金额：${extraInfo.orderAmount}，${message.message}`;
      default:
        return message.message;
    }
  }

  private toObject(message: InvestorMessage) {
    return message.extraInfo.reduce((result: Record<string, string>, kv) => {
      result[kv?.k!] = kv?.v!;
      return result;
    }, {});
    // 0: {k: 'paymentType', v: '网易', __typename: 'KeyValueObject'}
    // 1: {k: 'orderAmount', v: '11', __typename: 'KeyValueObject'}
    // 2: {k: 'orderUuid', v: '6a45957a-81ba-4371-9b7c-9711691dbf79', __typename: 'KeyValueObject'}
  }

  onTapAction(message: InvestorMessage) {
    switch (message.type) {
      case InvestorMessageTypeEnum.ShippingOrderConfirmed:
      case InvestorMessageTypeEnum.ShippingOrderPaid:
      case InvestorMessageTypeEnum.ShippingOrderGot:
      case InvestorMessageTypeEnum.NoticeToConfirmForShippingOrder:
        this.router.navigate(['/home']);
        break;
      case InvestorMessageTypeEnum.PurchaseOrderConfirmed:
      case InvestorMessageTypeEnum.PurchaseOrderGot:
      case InvestorMessageTypeEnum.NoticeToPayForPurchaseOrder:
        this.router.navigate(['/payment']);
        break;
      default:
        break;
    }
  }

  executeMessageAction(message: InvestorMessage) {
    switch (message.type) {
      case InvestorMessageTypeEnum.AutomaticDisableInvestorShipping:
      case InvestorMessageTypeEnum.DisableShippingBecauseQuota:
        this.orderSettingSer.refetchSetting();
        break;
      case InvestorMessageTypeEnum.ShippingOrderGot:
        this.missionSer.refetchShippingSetting();
        break;
      default:
        break;
    }
  }

  private playMessageSound(message: InvestorMessage) {
    switch (message.type) {
      case InvestorMessageTypeEnum.NoticeToPayForPurchaseOrder:
      case InvestorMessageTypeEnum.PurchaseOrderConfirmed:
        this.audioSer.playAudio('/assets/media/check_purchase_order.mp3');
        break;
      case InvestorMessageTypeEnum.ShippingOrderConfirmed:
      case InvestorMessageTypeEnum.NoticeToConfirmForShippingOrder:
        this.audioSer.playAudio('/assets/media/check_shipping_order.mp3');
        break;
      case InvestorMessageTypeEnum.AbnormalPaymentAccount:
      case InvestorMessageTypeEnum.AbnormalAlipayPaymentAccount:
      case InvestorMessageTypeEnum.AbnormalAlipayScanAccount:
      case InvestorMessageTypeEnum.AbnormalWechatScanAccount:
        this.audioSer.playAudio('/assets/media/abnormal_payment_account.mp3');
        break;
      case InvestorMessageTypeEnum.RechargeQuotaSuccess:
        this.audioSer.playAudio('/assets/media/recharge_success.mp3');
        break;
      case InvestorMessageTypeEnum.WithdrawQuotaSuccess:
      case InvestorMessageTypeEnum.WithdrawBonusSuccess:
        this.audioSer.playAudio('/assets/media/withdraw_success.mp3');
        break;
      case InvestorMessageTypeEnum.WithdrawQuotaFailure:
      case InvestorMessageTypeEnum.WithdrawBonusFailure:
        this.audioSer.playAudio('/assets/media/withdraw_fail.mp3');
        break;
      case InvestorMessageTypeEnum.PurchaseOrderGot:
      case InvestorMessageTypeEnum.ShippingOrderGot:
        // 与有单有单播放重复，故不处理
        break;
      default:
        this.audioSer.playAudio('/assets/media/you_have_new_message.mp3');
        break;
    }
  }

  openPurchaseModal(purchaseOrderUuid: string) {
    this.modalSer.open(PurchaseOrderInfoModalComponent, {
      hasBackdrop: false,
      width: '100%',
      height: '100%',
      panelClass: ['slide-right-in', 'slide-modal-bg'],
    }, component => {
      component.purchaseOrderUuid = purchaseOrderUuid;
    });
  }

  openShippingModal(shippingOrder: ShippingOrder) {
    this.modalSer.open(ShippingOrderInfoModalComponent, {
      hasBackdrop: false,
      width: '100%',
      height: '100%',
      panelClass: ['slide-right-in', 'slide-modal-bg'],
    }, component => {
      component.order = shippingOrder;
    });
  }
}
