export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type AddInvestorAlipayPaymentAccountInput = {
  /**  支付宝账号  */
  alipayAccount: Scalars['String'];
  /**  支付宝姓名  */
  alipayName: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorAlipayPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type AddInvestorAlipayScanAccountInput = {
  /**  支付宝姓名  */
  alipayName: Scalars['String'];
  /**  支付宝扫码账号图片  */
  qrcodeImage?: Maybe<Scalars['Upload']>;
  /**  支付宝扫码账号 UID  */
  alipayUid?: Maybe<Scalars['String']>;
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorAlipayScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type AddInvestorPaymentAccountInput = {
  /**  银行账号  */
  bankAccount: Scalars['String'];
  /**  银行账号户名  */
  bankAccountName: Scalars['String'];
  /**  银行编号  */
  bankId: Scalars['String'];
  /**  银行分行名称  */
  bankBranchName: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type AddInvestorWechatScanAccountInput = {
  /**  微信姓名  */
  wechatName: Scalars['String'];
  /**  微信扫码账号图片  */
  qrcodeImage: Scalars['Upload'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorWechatScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type AddInvestorWithdrawBankAccountInput = {
  /**  银行账号  */
  bankAccount: Scalars['String'];
  /**  银行账号户名  */
  bankAccountName: Scalars['String'];
  /**  银行编号  */
  bankId: Scalars['String'];
  /**  银行分行名称  */
  bankBranchName: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorWithdrawBankAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  新增提现用钱包地址  */
export type AddInvestorWithdrawWalletAddressInput = {
  /**  钱包地址  */
  walletAddress: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type AddInvestorWithdrawWalletAddressResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type Announcement = {
  /**  编号  */
  id: Scalars['ID'];
  /**  标题  */
  title?: Maybe<Scalars['String']>;
  /**  内容  */
  content?: Maybe<Scalars['String']>;
  /**  开始时间  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  结束时间  */
  endTime?: Maybe<Scalars['DateTime']>;
  /**  排序，从小到大  */
  order?: Maybe<Scalars['Int']>;
};

/** A paginated list of Announcement items. */
export type AnnouncementPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Announcement items. */
  data: Array<Announcement>;
};

export type AnnouncementsInput = {
  /**  公告编号  */
  announcementId?: Maybe<Scalars['String']>;
};

export type AppConfig = {
  /**  苹果应用相关设定  */
  ios?: Maybe<MobileConfig>;
  /**  安卓应用相关设定  */
  android?: Maybe<MobileConfig>;
};

/**  进货单申请审核  */
export type ApplyPurchaseOrderApprovalInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['String'];
  /**  简讯  */
  mobileMessage?: Maybe<Scalars['String']>;
  /**  出款账号  */
  bankAccountId: Scalars['String'];
};

export type ApplyPurchaseOrderApprovalResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type Authenticator = {
  /**  取得验证器 QRCODE  */
  getAuthenticatorQrCode?: Maybe<GetAuthenticatorQrCodeResult>;
  /**  绑定验证器  */
  bindAuthenticator?: Maybe<BindAuthenticatorResult>;
};


export type Authenticator_BindAuthenticatorArgs = {
  input?: Maybe<BindAuthenticatorInput>;
};

export type Bank = {
  /**  银行id  */
  id: Scalars['ID'];
  /**  银行名称  */
  name: Scalars['String'];
};

export type Banner = {
  /** 编号 */
  id: Scalars['ID'];
  /** 标题 */
  title: Scalars['String'];
  /** 目的地 URL */
  destinationUrl?: Maybe<Scalars['String']>;
  /** 连结开启方式 */
  urlOpenTarget: HtmlATargetEnum;
  /** 图片 URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** 排序，从小到大 */
  order?: Maybe<Scalars['Int']>;
  /** 开始时间 */
  startTime?: Maybe<Scalars['DateTime']>;
  /** 结束时间 */
  endTime?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Banner items. */
export type BannerPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Banner items. */
  data: Array<Banner>;
};

export type BannersInput = {
  /**  广告编号  */
  bannerId?: Maybe<Scalars['String']>;
};

export type BindAuthenticatorInput = {
  /**  密钥  */
  secret: Scalars['String'];
  /**  验证器验证码  */
  authCode: Scalars['String'];
};

export type BindAuthenticatorResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type CancelRechargeOrderInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['String'];
};

export type CancelRechargeOrderResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type ChildInvestor = {
  /**  账号  */
  account: Scalars['String'];
  /**  团队人数额度  */
  teamMemberQuota: Scalars['Int'];
  /**  网易红利比例(%)  */
  netEasePercent: Scalars['Float'];
  /**  YOHO 红利比例(%)  */
  yohoPercent: Scalars['Float'];
  /**  支付宝红利比例(%)  */
  alipayPercent: Scalars['Float'];
  /**  支付宝扫码红利比例(%)  */
  alipayScanPercent: Scalars['Float'];
  /**  微信扫码红利比例(%)  */
  wechatScanPercent: Scalars['Float'];
  /**  支付宝(代付)红利比例(%)  */
  purchaseAlipayPercent: Scalars['Float'];
};

export type ChildInvestorInput = {
  /**  投资者账号  */
  account: Scalars['String'];
};

export enum ClientPurchaseOrderStatusEnum {
  /** 系统处理中 */
  Processing = 'PROCESSING',
  /** 成功 */
  Success = 'SUCCESS',
  /** 失败 */
  Failure = 'FAILURE',
  /** 待审核 */
  WaitForGatewayApproval = 'WAIT_FOR_GATEWAY_APPROVAL',
  /** 处理中 */
  InvestorProcessing = 'INVESTOR_PROCESSING',
  /** 未开启 */
  InvestorToBeConfirmed = 'INVESTOR_TO_BE_CONFIRMED',
  /** 待认领 */
  WaitForInvestor = 'WAIT_FOR_INVESTOR',
  /** 待审核 */
  WaitForReview = 'WAIT_FOR_REVIEW',
  /** 锁定 */
  Locked = 'LOCKED'
}

export enum ClientShippingOrderStatusEnum {
  /** 处理中 */
  Processing = 'PROCESSING',
  /** 待确认收款 */
  Confirming = 'CONFIRMING',
  /** 成功 */
  Success = 'SUCCESS',
  /** 失败 */
  Failure = 'FAILURE',
  /** 待审核 */
  WaitForApproval = 'WAIT_FOR_APPROVAL'
}

export type Commodity = {
  /**  商品编号  */
  id: Scalars['ID'];
  /**  商品名称  */
  name: Scalars['String'];
  /**  商品价格  */
  price: Scalars['Float'];
  /**  商品图片网址  */
  image: Scalars['String'];
};

export type Config = {
  /**  SMS 设置  */
  smsConfig?: Maybe<SmsConfigResult>;
  /**  投资者设定  */
  investorConfig?: Maybe<InvestorConfig>;
  /**  APP 相关设定  */
  appConfig?: Maybe<AppConfig>;
  /**  货币相关设定  */
  currencyConfigs: Array<CurrencyConfig>;
};


export type Config_CurrencyConfigsArgs = {
  input: CurrencyConfigsInput;
};

/**  确认领取进货单  */
export type ConfirmPurchaseOrderInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['String'];
};

export type ConfirmPurchaseOrderResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  确认出货单完成用  */
export type ConfirmShippingOrderInput = {
  /**  订单编号  */
  orderUuid: Scalars['String'];
  /**  姓名  */
  name: Scalars['String'];
  /**  金额  */
  amount: Scalars['Float'];
  /**  简讯  */
  mobileMessage?: Maybe<Scalars['String']>;
};

export type ConfirmShippingOrderResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type ConvertBonusToQuotaInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  数量  */
  amount: Scalars['Float'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type ConvertBonusToQuotaResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type CreateChildInvestorInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  密码  */
  password: Scalars['String'];
  /**  团队人数额度 (含自身)  */
  teamMemberQuota: Scalars['Int'];
  /**  网易红利费率(%)  */
  netEasePercent: Scalars['String'];
  /**  YOHO 红利费率(%)  */
  yohoPercent: Scalars['String'];
  /**  支付宝(代收)红利费率(%)  */
  alipayPercent: Scalars['String'];
  /**  支付宝(代付)红利费率(%)  */
  purchaseAlipayPercent: Scalars['String'];
  /**  支付宝扫码(代收)红利费率(%)  */
  alipayScanPercent: Scalars['String'];
  /**  微信扫码(代收)红利费率(%)  */
  wechatScanPercent: Scalars['String'];
};

export type CreateChildInvestorResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type CreateSignupCodeInput = {
  /**  网易红利费率(%)  */
  netEasePercent: Scalars['String'];
  /**  YOHO 红利费率(%)  */
  yohoPercent: Scalars['String'];
  /**  支付宝(代收)红利费率(%)  */
  alipayPercent: Scalars['String'];
  /**  支付宝扫码(代收)红利费率(%)  */
  alipayScanPercent: Scalars['String'];
  /**  微信扫码(代收)红利费率(%)  */
  wechatScanPercent: Scalars['String'];
  /**  支付宝(代付)红利费率(%)  */
  purchaseAlipayPercent: Scalars['String'];
  /**  备注  */
  remark?: Maybe<Scalars['String']>;
};

export type CreateSignupCodeResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type CurrencyConfig = {
  /**  唯一编号  */
  id: Scalars['ID'];
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  额度充值人民币单笔金额上限  */
  investorRechargeQuotaMax: Scalars['Float'];
  /**  额度充值人民币单笔金额下限  */
  investorRechargeQuotaMin: Scalars['Float'];
  /**  投资者红利提领手续费  */
  investorBonusWithdrawFee: Scalars['Float'];
  /**  投资者提领最小数量  */
  investorWithdrawMin: Scalars['Float'];
  /**  投资者额度提现手续费最低金额  */
  investorQuotaWithdrawFeeMin: Scalars['Float'];
};

export type CurrencyConfigsInput = {
  /**  欲查询的币种  */
  currencyTypes: Array<CurrencyTypeEnum>;
};

export enum CurrencyTypeEnum {
  /** 人民币 */
  Cny = 'CNY',
  /** 美元 */
  Usd = 'USD',
  /** USDT */
  Usdt = 'USDT'
}

export type CurrencyTypeInput = {
  /**  欲查询的币种  */
  currencyTypes: Array<CurrencyTypeEnum>;
};

export type CustomPaginatorInfo = {
  /**  当前页数  */
  currentPage: Scalars['Int'];
  /**  总笔数  */
  total: Scalars['Int'];
  /**  是否还有下一页  */
  hasMorePages: Scalars['Boolean'];
  /**  最后页数  */
  lastPage: Scalars['Int'];
};

/**  删除投资者支付宝收款账号  */
export type DeleteInvestorAlipayPaymentAccountInput = {
  /**  支付宝收款账号编号  */
  paymentAccountId: Scalars['String'];
};

export type DeleteInvestorAlipayPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  删除投资者支付宝扫码收款账号  */
export type DeleteInvestorAlipayScanAccountInput = {
  /**  支付宝扫码收款账号编号  */
  paymentAccountId: Scalars['String'];
};

export type DeleteInvestorAlipayScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  删除投资者收款账号  */
export type DeleteInvestorPaymentAccountInput = {
  /**  收款账号编号  */
  paymentAccountId: Scalars['String'];
};

export type DeleteInvestorPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  删除投资者微信扫码收款账号  */
export type DeleteInvestorWechatScanAccountInput = {
  /**  微信扫码收款账号编号  */
  paymentAccountId: Scalars['String'];
};

export type DeleteInvestorWechatScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type DeleteSignupCodeInput = {
  /**  编号  */
  id: Scalars['ID'];
};

export type DeleteSignupCodeResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type EditChildInvestorInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  团队人数额度 (含自身)  */
  teamMemberQuota: Scalars['Int'];
  /**  网易红利费率(%)  */
  netEasePercent: Scalars['String'];
  /**  YOHO 红利费率(%)  */
  yohoPercent: Scalars['String'];
  /**  支付宝(代收)红利费率(%)  */
  alipayPercent: Scalars['String'];
  /**  支付宝扫码(代收)红利费率(%)  */
  alipayScanPercent: Scalars['String'];
  /**  微信扫码(代收)红利费率(%)  */
  wechatScanPercent: Scalars['String'];
  /**  支付宝(代付)红利费率(%)  */
  purchaseAlipayPercent: Scalars['String'];
};

export type EditChildInvestorResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  启用投资者支付宝收款账号  */
export type EnableInvestorAlipayPaymentAccountInput = {
  /**  支付宝收款账号编号  */
  paymentAccountId: Scalars['String'];
  /**  开启  */
  enabled: Scalars['Boolean'];
};

export type EnableInvestorAlipayPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  启用投资者支付宝扫码收款账号  */
export type EnableInvestorAlipayScanAccountInput = {
  /**  支付宝扫码收款账号编号  */
  paymentAccountId: Scalars['String'];
  /**  开启  */
  enabled: Scalars['Boolean'];
};

export type EnableInvestorAlipayScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  启用投资者收款账号  */
export type EnableInvestorPaymentAccountInput = {
  /**  收款账号编号  */
  paymentAccountId: Scalars['String'];
  /**  开启  */
  enabled: Scalars['Boolean'];
};

export type EnableInvestorPaymentAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  启用投资者微信扫码收款账号  */
export type EnableInvestorWechatScanAccountInput = {
  /**  微信扫码收款账号编号  */
  paymentAccountId: Scalars['String'];
  /**  开启  */
  enabled: Scalars['Boolean'];
};

export type EnableInvestorWechatScanAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type EnumItem = {
  /**  键  */
  key: Scalars['String'];
  /**  描述  */
  description: Scalars['String'];
};

export type Enums = {
  /**  进货单状态  */
  purchaseOrderStatus: Array<EnumItem>;
  /**  出货单状态  */
  shippingOrderStatus: Array<EnumItem>;
  /**  投资者额度异动类型  */
  investorQuotaChangeType: Array<EnumItem>;
  /**  投资者红利异动类型  */
  investorBonusChangeType: Array<EnumItem>;
  /**  厂商额度异动类型  */
  merchantQuotaChangeType: Array<EnumItem>;
  /**  出货单异常类型  */
  shippingOrderProblemType: Array<EnumItem>;
  /**  交易所订单状态  */
  exchangeWebsiteOrderStatus: Array<EnumItem>;
};

export enum ExchangeWebsiteOrderStatusEnum {
  /** 成功 */
  Success = 'SUCCESS',
  /** 失败 */
  Failure = 'FAILURE'
}

export type GetAuthenticatorQrCodeResult = {
  /**  base64 URL  */
  base64ImageUrl: Scalars['String'];
  /**  密钥  */
  secret: Scalars['String'];
};

export enum HtmlATargetEnum {
  /** NONE */
  None = 'NONE',
  /** BLANK */
  Blank = 'BLANK',
  /** SELF */
  Self = 'SELF',
  /** PARENT */
  Parent = 'PARENT',
  /** TOP */
  Top = 'TOP'
}

export type Investor = {
  /**  编号  */
  id: Scalars['ID'];
  /**  账号  */
  account: Scalars['String'];
  /**  昵称  */
  name?: Maybe<Scalars['String']>;
  /**  QQ 账号  */
  qqAccount?: Maybe<Scalars['String']>;
  /**  手机号码  */
  mobileNumber?: Maybe<Scalars['String']>;
  /**  备用手机号码  */
  mobileNumber2nd?: Maybe<Scalars['String']>;
  /**  是否接受出货单  */
  shippingEnabled?: Maybe<Scalars['Boolean']>;
  /**  接受出货单的收款方式  */
  activeShippingPaymentTypes: Array<InvestorPaymentTypeEnum>;
  /**  接受进货单的付款方式  */
  activePurchasePaymentTypes: Array<InvestorPaymentTypeEnum>;
  /**  是否接受进货单  */
  purchaseEnabled?: Maybe<Scalars['Boolean']>;
  /**  是否设定安全密码  */
  hasSecurityPassword: Scalars['Boolean'];
  /**  是否设定验证器  */
  hasAuthenticator: Scalars['Boolean'];
  /**  钱包  */
  wallets: Array<InvestorWallet>;
  /**  分配到的出货订单  */
  shippingOrders: Array<ShippingOrder>;
  /**  可接或处理中的进货订单  */
  purchaseOrders: Array<PurchaseOrder>;
  /**  订单资讯  */
  orderInfo?: Maybe<InvestorOrderInfo>;
  /**  历史相关  */
  histories?: Maybe<InvestorHistory>;
  /**  收款账号  */
  paymentAccounts: Array<InvestorPaymentAccount>;
  /**  金融相关资讯  */
  financeInfo?: Maybe<InvestorFinanceInfo>;
  /**  团队资讯  */
  team?: Maybe<InvestorTeam>;
  /**  未读讯息数量  */
  unreadMessageCount: Scalars['Int'];
  /**  提现账号  */
  withdrawBankAccounts: Array<InvestorWithdrawBankAccount>;
  /**  总代奖金制度群组  */
  rootBonusSystemGroup: InvestorRootBonusSystemGroup;
  /**  设定相关  */
  settings?: Maybe<InvestorSettings>;
  /**  是否可以创建创建注册码  */
  canCreateSignupCode: Scalars['Boolean'];
  /**  密码资讯  */
  passwordInfo?: Maybe<InvestorPasswordInfo>;
  /**  提现钱包地址  */
  withdrawWalletAddresses: Array<InvestorWithdrawWalletAddress>;
  /**  汇率  */
  exchange?: Maybe<InvestorExchange>;
  /**  是否为新注册  */
  isNewRegister: Scalars['Boolean'];
  /**  交易站点资讯  */
  exchangeWebsiteInfo?: Maybe<InvestorExchangeWebsiteInfo>;
  /**  支付宝收款账号  */
  alipayPaymentAccounts: Array<InvestorAlipayPaymentAccount>;
  /**  支付宝扫码收款账号  */
  alipayScanAccounts: Array<InvestorAlipayScanAccount>;
  /**  微信扫码收款账号  */
  wechatScanAccounts: Array<InvestorWechatScanAccount>;
  /**  讯息  */
  messages?: Maybe<InvestorMessagePaginator>;
};


export type Investor_WalletsArgs = {
  input: InvestorWalletsInput;
};


export type Investor_ShippingOrdersArgs = {
  input?: Maybe<InvestorShippingOrdersInput>;
};


export type Investor_PurchaseOrdersArgs = {
  input: InvestorPurchaseOrdersInput;
};


export type Investor_MessagesArgs = {
  input: InvestorMessagesInput;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type InvestorAlipayPaymentAccount = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  支付方式  */
  paymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  支付宝账号  */
  alipayAccount?: Maybe<Scalars['String']>;
  /**  支付宝姓名  */
  alipayName?: Maybe<Scalars['String']>;
  /**  状态  */
  status: InvestorAlipayPaymentAccountStatusEnum;
  /**  今日收款金额  */
  todayShippingTotalAmount: Scalars['Float'];
};

export enum InvestorAlipayPaymentAccountStatusEnum {
  /** 启用 */
  Enabled = 'ENABLED',
  /** 关闭 */
  Disabled = 'DISABLED',
  /** 停用 */
  DisabledByAbnormal = 'DISABLED_BY_ABNORMAL',
  /** 删除 */
  Deleted = 'DELETED'
}

export type InvestorAlipayScanAccount = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  支付方式  */
  paymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  支付宝姓名  */
  alipayName?: Maybe<Scalars['String']>;
  /**  支付宝 UID  */
  alipayUid?: Maybe<Scalars['String']>;
  /**  支付宝扫码图片 URL  */
  qrcodeUrl?: Maybe<Scalars['String']>;
  /**  状态  */
  status: InvestorAlipayScanAccountStatusEnum;
  /**  今日收款金额  */
  todayShippingTotalAmount: Scalars['Float'];
};

export enum InvestorAlipayScanAccountStatusEnum {
  /** 启用 */
  Enabled = 'ENABLED',
  /** 关闭 */
  Disabled = 'DISABLED',
  /** 停用 */
  DisabledByAbnormal = 'DISABLED_BY_ABNORMAL',
  /** 删除 */
  Deleted = 'DELETED'
}

export enum InvestorAuthenticationModeEnum {
  /** 不需验证 */
  PassAuthentication = 'PASS_AUTHENTICATION',
  /** 安全密码 */
  SecurityPassword = 'SECURITY_PASSWORD',
  /** 验证器 */
  Authenticator = 'AUTHENTICATOR',
  /** 短信验证 */
  SmsCode = 'SMS_CODE'
}

export enum InvestorBonusChangeTypeEnum {
  /** 收款汇差调整 */
  ShippingBonus = 'SHIPPING_BONUS',
  /** 付款汇差调整 */
  PurchaseBonus = 'PURCHASE_BONUS',
  /** 收款上级汇差调整 */
  ShippingSuperiorBonus = 'SHIPPING_SUPERIOR_BONUS',
  /** 付款上级汇差调整 */
  PurchaseSuperiorBonus = 'PURCHASE_SUPERIOR_BONUS',
  /** 提现 */
  Withdraw = 'WITHDRAW',
  /** 提现失败 */
  WithdrawFailure = 'WITHDRAW_FAILURE',
  /** 进货成功调账 */
  PurchaseOrderPositiveOffset = 'PURCHASE_ORDER_POSITIVE_OFFSET',
  /** 进货失败调账 */
  PurchaseOrderNegativeOffset = 'PURCHASE_ORDER_NEGATIVE_OFFSET',
  /** 活动-注册金 */
  EventSignupReward = 'EVENT_SIGNUP_REWARD',
  /** 收款层级红利 */
  ShippingLevelBonus = 'SHIPPING_LEVEL_BONUS',
  /** 付款层级红利 */
  PurchaseLevelBonus = 'PURCHASE_LEVEL_BONUS',
  /** 注册金 */
  SignupReward = 'SIGNUP_REWARD',
  /** 冻结 */
  Freeze = 'FREEZE',
  /** 解冻 */
  Unfreeze = 'UNFREEZE',
  /** 红利转额度扣款 */
  ConvertBonusToQuota = 'CONVERT_BONUS_TO_QUOTA',
  /** 出货失败调账 */
  ShippingOrderNegativeOffset = 'SHIPPING_ORDER_NEGATIVE_OFFSET',
  /** 活动-出货额外红利 */
  EventShippingExtraBonus = 'EVENT_SHIPPING_EXTRA_BONUS',
  /** 出货补单失败 */
  ReplicatedShippingOrderFailure = 'REPLICATED_SHIPPING_ORDER_FAILURE',
  /** 出货补单 */
  ReplicatedShippingOrder = 'REPLICATED_SHIPPING_ORDER',
  /** 红利转账 */
  TransferBonus = 'TRANSFER_BONUS',
  /** 支付宝(代收)红利 */
  ShippingAlipayPercentReward = 'SHIPPING_ALIPAY_PERCENT_REWARD',
  /** 支付宝(代付)红利 */
  PurchaseAlipayPercentReward = 'PURCHASE_ALIPAY_PERCENT_REWARD',
  /** 余额调整 */
  AdjustBalance = 'ADJUST_BALANCE',
  /** 公司损失 */
  CompanyLoss = 'COMPANY_LOSS',
  /** 操作错误 */
  WrongOperation = 'WRONG_OPERATION',
  /** 余额转移 */
  TransferBalance = 'TRANSFER_BALANCE',
  /** 其他 */
  Other = 'OTHER'
}

export type InvestorBonusHistoryInput = {
  /**  异动类型  */
  changeType?: Maybe<InvestorBonusChangeTypeEnum>;
  /**  货币类型  */
  currencyType?: Maybe<CurrencyTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  开始时间  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  结束时间  */
  endTime?: Maybe<Scalars['DateTime']>;
};

export type InvestorBonusRecord = {
  /**  异动原因  */
  changeType?: Maybe<InvestorBonusChangeTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  修改前  */
  changeBefore?: Maybe<Scalars['Float']>;
  /**  修改数量  */
  changeAmount?: Maybe<Scalars['Float']>;
  /**  修改后  */
  changeAfter?: Maybe<Scalars['Float']>;
  /**  时间  */
  createdAt?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of InvestorBonusRecord items. */
export type InvestorBonusRecordPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorBonusRecord items. */
  data: Array<InvestorBonusRecord>;
};

export type InvestorConfig = {
  /**  投资者提现未达业绩提现手续费率  */
  investorWithdrawQuotaFeePercentWithoutAchieveAchievement: Scalars['Float'];
  /**  投资者提现已达业绩提现手续费率  */
  investorWithdrawQuotaFeePercentWithAchieveAchievement: Scalars['Float'];
  /**  最后一笔交易 x 小时后才可以提领额度  */
  canWithdrawAfterLastTransactionXHours: Scalars['Float'];
  /**  额度提现业绩比例(%)  */
  investorWithdrawQuotaAchievementPercent: Scalars['Float'];
  /**  闲置关闭出货时间  */
  investorCloseShippingSeconds: Scalars['Int'];
  /**  心跳检查间隔(秒)  */
  heartbeatIntervalSeconds: Scalars['Int'];
  /**  闲置确认时间  */
  confirmOnlineCountdownSeconds: Scalars['Int'];
  /**  验证机制  */
  authenticationMode: InvestorAuthenticationModeEnum;
  /**  是否压缩上传的图片  */
  isCompressImageBeforeUpload: Scalars['Boolean'];
};

export type InvestorExchange = {
  /**  渠道汇率  */
  defaultGatewayRate?: Maybe<Scalars['Float']>;
  defaultGatewaySellRate?: Maybe<Scalars['Float']>;
  defaultGatewayDepositRate?: Maybe<Scalars['Float']>;
};

export type InvestorExchangeWebsiteInfo = {
  /**  市场投资者  */
  marketInvestors?: Maybe<MarketInvestorInfoPaginator>;
};


export type InvestorExchangeWebsiteInfo_MarketInvestorsArgs = {
  input?: Maybe<InvestorExchangeWebsiteMarketInvestorsInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type InvestorExchangeWebsiteMarketInvestorsInput = {
  /**  投资者  */
  account?: Maybe<Scalars['String']>;
};

export type InvestorFinance = {
  /**  将红利转换为额度  */
  convertBonusToQuota?: Maybe<ConvertBonusToQuotaResult>;
  /**  额度转账  */
  transferQuotaToOtherInvestor?: Maybe<TransferQuotaToOtherInvestorResult>;
  /**  额度提现  */
  withdrawQuota?: Maybe<WithdrawInvestorQuotaResult>;
  /**  红利提现  */
  withdrawBonus?: Maybe<WithdrawInvestorBonusResult>;
  /**  额度充值  */
  rechargeQuota?: Maybe<RechargeInvestorQuotaResult>;
  /**  新增提现用银行账号  */
  addInvestorWithdrawBankAccount?: Maybe<AddInvestorWithdrawBankAccountResult>;
  /**  新增收款账号  */
  addInvestorPaymentAccount?: Maybe<AddInvestorPaymentAccountResult>;
  /**  启用收款账号  */
  enablePaymentAccount?: Maybe<EnableInvestorPaymentAccountResult>;
  /**  删除收款账号  */
  deletePaymentAccount?: Maybe<DeleteInvestorPaymentAccountResult>;
  /**  额度提现到钱包地址  */
  withdrawQuotaByUsdt?: Maybe<WithdrawQuotaByUsdtResult>;
  /**  额度充值到钱包地址  */
  rechargeQuotaByUsdt?: Maybe<RechargeQuotaByUsdtResult>;
  /**  新增提现用钱包地址  */
  addInvestorWithdrawWalletAddress?: Maybe<AddInvestorWithdrawWalletAddressResult>;
  /**  取消充值  */
  cancelRechargeOrder?: Maybe<CancelRechargeOrderResult>;
  /**  新增支付宝收款账号  */
  addAlipayPaymentAccount: AddInvestorAlipayPaymentAccountResult;
  /**  启用支付宝收款账号  */
  enableAlipayPaymentAccount: EnableInvestorAlipayPaymentAccountResult;
  /**  删除支付宝收款账号  */
  deleteAlipayPaymentAccount: DeleteInvestorAlipayPaymentAccountResult;
  /**  新增支付宝扫码收款账号  */
  addAlipayScanAccount: AddInvestorAlipayScanAccountResult;
  /**  启用支付宝扫码收款账号  */
  enableAlipayScanAccount: EnableInvestorAlipayScanAccountResult;
  /**  删除支付宝扫码收款账号  */
  deleteAlipayScanAccount: DeleteInvestorAlipayScanAccountResult;
  /**  新增微信扫码收款账号  */
  addWechatScanAccount: AddInvestorWechatScanAccountResult;
  /**  启用微信扫码收款账号  */
  enableWechatScanAccount: EnableInvestorWechatScanAccountResult;
  /**  删除微信扫码收款账号  */
  deleteWechatScanAccount: DeleteInvestorWechatScanAccountResult;
};


export type InvestorFinance_ConvertBonusToQuotaArgs = {
  input: ConvertBonusToQuotaInput;
};


export type InvestorFinance_TransferQuotaToOtherInvestorArgs = {
  input: TransferQuotaToOtherInvestorInput;
};


export type InvestorFinance_WithdrawQuotaArgs = {
  input: WithdrawInvestorQuotaInput;
};


export type InvestorFinance_WithdrawBonusArgs = {
  input: WithdrawInvestorBonusInput;
};


export type InvestorFinance_RechargeQuotaArgs = {
  input: RechargeInvestorQuotaInput;
};


export type InvestorFinance_AddInvestorWithdrawBankAccountArgs = {
  input: AddInvestorWithdrawBankAccountInput;
};


export type InvestorFinance_AddInvestorPaymentAccountArgs = {
  input: AddInvestorPaymentAccountInput;
};


export type InvestorFinance_EnablePaymentAccountArgs = {
  input: EnableInvestorPaymentAccountInput;
};


export type InvestorFinance_DeletePaymentAccountArgs = {
  input: DeleteInvestorPaymentAccountInput;
};


export type InvestorFinance_WithdrawQuotaByUsdtArgs = {
  input: WithdrawQuotaByUsdtInput;
};


export type InvestorFinance_RechargeQuotaByUsdtArgs = {
  input: RechargeQuotaByUsdtInput;
};


export type InvestorFinance_AddInvestorWithdrawWalletAddressArgs = {
  input: AddInvestorWithdrawWalletAddressInput;
};


export type InvestorFinance_CancelRechargeOrderArgs = {
  input: CancelRechargeOrderInput;
};


export type InvestorFinance_AddAlipayPaymentAccountArgs = {
  input: AddInvestorAlipayPaymentAccountInput;
};


export type InvestorFinance_EnableAlipayPaymentAccountArgs = {
  input: EnableInvestorAlipayPaymentAccountInput;
};


export type InvestorFinance_DeleteAlipayPaymentAccountArgs = {
  input: DeleteInvestorAlipayPaymentAccountInput;
};


export type InvestorFinance_AddAlipayScanAccountArgs = {
  input: AddInvestorAlipayScanAccountInput;
};


export type InvestorFinance_EnableAlipayScanAccountArgs = {
  input: EnableInvestorAlipayScanAccountInput;
};


export type InvestorFinance_DeleteAlipayScanAccountArgs = {
  input: DeleteInvestorAlipayScanAccountInput;
};


export type InvestorFinance_AddWechatScanAccountArgs = {
  input: AddInvestorWechatScanAccountInput;
};


export type InvestorFinance_EnableWechatScanAccountArgs = {
  input: EnableInvestorWechatScanAccountInput;
};


export type InvestorFinance_DeleteWechatScanAccountArgs = {
  input: DeleteInvestorWechatScanAccountInput;
};

export type InvestorFinanceInfo = {
  levelGroup?: Maybe<LevelGroup>;
  levelGroupQuotas: Array<LevelGroupQuota>;
};


export type InvestorFinanceInfo_LevelGroupQuotasArgs = {
  input: CurrencyTypeInput;
};

export type InvestorHistory = {
  /**  进货历史订单  */
  purchaseOrderHistory?: Maybe<PurchaseOrderPaginator>;
  /**  出货历史订单  */
  shippingOrderHistory?: Maybe<ShippingOrderPaginator>;
  /**  额度异动  */
  quotaHistory?: Maybe<InvestorQuotaRecordPaginator>;
  /**  额度异动  */
  bonusHistory?: Maybe<InvestorBonusRecordPaginator>;
};


export type InvestorHistory_PurchaseOrderHistoryArgs = {
  input?: Maybe<InvestorPurchaseOrderHistoryInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type InvestorHistory_ShippingOrderHistoryArgs = {
  input?: Maybe<InvestorShippingOrderHistoryInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type InvestorHistory_QuotaHistoryArgs = {
  input?: Maybe<InvestorQuotaHistoryInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type InvestorHistory_BonusHistoryArgs = {
  input?: Maybe<InvestorBonusHistoryInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type InvestorMessage = {
  /**  讯息编号  */
  id: Scalars['ID'];
  /**  讯息内容  */
  message: Scalars['String'];
  /**  是否已读  */
  isRead: Scalars['Boolean'];
  /**  讯息类型  */
  type: InvestorMessageTypeEnum;
  /**  额外资讯  */
  extraInfo: Array<Maybe<KeyValueObject>>;
  /**  标题  */
  title: Scalars['String'];
  /**  创建时间  */
  createdAt: Scalars['DateTime'];
};

/** A paginated list of InvestorMessage items. */
export type InvestorMessagePaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorMessage items. */
  data: Array<InvestorMessage>;
};

export enum InvestorMessageTypeEnum {
  /** 通知讯息 */
  Notice = 'NOTICE',
  /** 请开启付款订单 */
  NoticeToPayForPurchaseOrder = 'NOTICE_TO_PAY_FOR_PURCHASE_ORDER',
  /** 额度充值成功 */
  RechargeQuotaSuccess = 'RECHARGE_QUOTA_SUCCESS',
  /** 额度提现成功 */
  WithdrawQuotaSuccess = 'WITHDRAW_QUOTA_SUCCESS',
  /** 红利提现成功 */
  WithdrawBonusSuccess = 'WITHDRAW_BONUS_SUCCESS',
  /** 额度提现失败 */
  WithdrawQuotaFailure = 'WITHDRAW_QUOTA_FAILURE',
  /** 红利提现失败 */
  WithdrawBonusFailure = 'WITHDRAW_BONUS_FAILURE',
  /** 收款账号异常 */
  AbnormalPaymentAccount = 'ABNORMAL_PAYMENT_ACCOUNT',
  /** 收款订单确认 */
  ShippingOrderConfirmed = 'SHIPPING_ORDER_CONFIRMED',
  /** 付款订单确认 */
  PurchaseOrderConfirmed = 'PURCHASE_ORDER_CONFIRMED',
  /** 收款订单已付款 */
  ShippingOrderPaid = 'SHIPPING_ORDER_PAID',
  /** 收款银行维护中，收款已关闭 */
  AutomaticDisableInvestorShipping = 'AUTOMATIC_DISABLE_INVESTOR_SHIPPING',
  /** 收款银行卡异常，收款已关闭 */
  BlockedPaymentBankAccount = 'BLOCKED_PAYMENT_BANK_ACCOUNT',
  /** 提现银行卡异常 */
  BlockedWithdrawBankAccount = 'BLOCKED_WITHDRAW_BANK_ACCOUNT',
  /** 可用余额过低，收款已关闭 */
  DisableShippingBecauseQuota = 'DISABLE_SHIPPING_BECAUSE_QUOTA',
  /** 收款订单接单 */
  ShippingOrderGot = 'SHIPPING_ORDER_GOT',
  /** 付款订单接单 */
  PurchaseOrderGot = 'PURCHASE_ORDER_GOT',
  /** 收款订单超时 */
  NoticeToConfirmForShippingOrder = 'NOTICE_TO_CONFIRM_FOR_SHIPPING_ORDER',
  /** 额度充值失败 */
  RechargeQuotaFailure = 'RECHARGE_QUOTA_FAILURE',
  /** 支付宝收款账号异常 */
  AbnormalAlipayPaymentAccount = 'ABNORMAL_ALIPAY_PAYMENT_ACCOUNT',
  /** 支付宝扫码收款账号异常 */
  AbnormalAlipayScanAccount = 'ABNORMAL_ALIPAY_SCAN_ACCOUNT',
  /** 微信扫码收款账号异常 */
  AbnormalWechatScanAccount = 'ABNORMAL_WECHAT_SCAN_ACCOUNT'
}

export type InvestorMessagesInput = {
  /**  查询编号  */
  messageIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /**  是否已读 (不传代表全部)  */
  isRead?: Maybe<Scalars['Boolean']>;
};

export type InvestorMutation = {
  /**  设定接单与否  */
  setOrderEnabled?: Maybe<SetOrderEnabledResult>;
  /**  确认出货完成  */
  confirmShippingOrder?: Maybe<ConfirmShippingOrderResult>;
  /**  领取进货单  */
  receivePurchaseOrder?: Maybe<ReceivePurchaseOrderResult>;
  /**  设定名称  */
  setName?: Maybe<SetInvestorNameResult>;
  /**  设定 QQ 账号  */
  setQqAccount?: Maybe<SetInvestorQqAccountResult>;
  /**  设定登入密码  */
  setLoginPassword?: Maybe<SetInvestorLoginPasswordResult>;
  /**  设定安全密码  */
  setSecurityPassword?: Maybe<SetInvestorSecurityPasswordResult>;
  /**  设定手机号码  */
  setMobileNumber?: Maybe<SetInvestorMobileNumberResult>;
  /**  发送 SMS 验证码  */
  sendAuthCode?: Maybe<SendAuthCodeResult>;
  /**  设定进货订单金额限制  */
  setPurchaseOrderAmountLimit?: Maybe<SetPurchaseOrderAmountLimitResult>;
  /**  出货单申请异常回报  */
  reportShippingOrderProblem?: Maybe<ReportShippingOrderProblemResult>;
  /**  刷新心跳机制秒数  */
  reportHeartbeat?: Maybe<InvestorReportHeartbeatResult>;
  /**  进货单上传档案  */
  uploadPurchaseOrderFile?: Maybe<UploadPurchaseOrderFileResult>;
  /**  进货单申请审核  */
  applyPurchaseOrderApproval?: Maybe<ApplyPurchaseOrderApprovalResult>;
  /**  设定讯息已读  */
  markMessageAsRead?: Maybe<MarkMessageAsReadResult>;
  /**  验证器  */
  authenticator?: Maybe<Authenticator>;
  /**  团队操作相关  */
  team?: Maybe<InvestorTeamMutation>;
  /**  登出  */
  logout: LogoutResult;
  /**  确认领取进货单  */
  confirmPurchaseOrder?: Maybe<ConfirmPurchaseOrderResult>;
  /**  金融相關  */
  finance?: Maybe<InvestorFinance>;
};


export type InvestorMutation_SetOrderEnabledArgs = {
  input: SetOrderEnabledInput;
};


export type InvestorMutation_ConfirmShippingOrderArgs = {
  input: ConfirmShippingOrderInput;
};


export type InvestorMutation_ReceivePurchaseOrderArgs = {
  input: ReceivePurchaseOrderInput;
};


export type InvestorMutation_SetNameArgs = {
  input: SetInvestorNameInput;
};


export type InvestorMutation_SetQqAccountArgs = {
  input: SetInvestorQqAccountInput;
};


export type InvestorMutation_SetLoginPasswordArgs = {
  input: SetInvestorLoginPasswordInput;
};


export type InvestorMutation_SetSecurityPasswordArgs = {
  input: SetInvestorSecurityPasswordInput;
};


export type InvestorMutation_SetMobileNumberArgs = {
  input: SetInvestorMobileNumberInput;
};


export type InvestorMutation_SendAuthCodeArgs = {
  input: SendAuthCodeInput;
};


export type InvestorMutation_SetPurchaseOrderAmountLimitArgs = {
  input: SetPurchaseOrderAmountLimitInput;
};


export type InvestorMutation_ReportShippingOrderProblemArgs = {
  input: ReportShippingOrderProblemInput;
};


export type InvestorMutation_UploadPurchaseOrderFileArgs = {
  input: UploadPurchaseOrderFileInput;
};


export type InvestorMutation_ApplyPurchaseOrderApprovalArgs = {
  input: ApplyPurchaseOrderApprovalInput;
};


export type InvestorMutation_MarkMessageAsReadArgs = {
  input: MarkMessageAsReadInput;
};


export type InvestorMutation_ConfirmPurchaseOrderArgs = {
  input: ConfirmPurchaseOrderInput;
};

export type InvestorOrderInfo = {
  /**  进货订单详细  */
  purchaseOrderInfo?: Maybe<InvestorPurchaseOrderInfo>;
  /**  出货订单详细  */
  shippingOrderInfo?: Maybe<InvestorShippingOrderInfo>;
};


export type InvestorOrderInfo_PurchaseOrderInfoArgs = {
  input: InvestorPurchaseOrderInfoInput;
};


export type InvestorOrderInfo_ShippingOrderInfoArgs = {
  input: InvestorShippingOrderInfoInput;
};

export type InvestorPasswordInfo = {
  /**  弱登入密码  */
  isLoginPasswordWeak: Scalars['Boolean'];
  /**  首次登入后第一次修改密码的时间  */
  firstLoginPasswordChangedAtAfterFirstLogin?: Maybe<Scalars['DateTime']>;
};

export type InvestorPaymentAccount = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  支付方式  */
  paymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  银行名称  */
  bankName?: Maybe<Scalars['String']>;
  /**  银行分行名称  */
  bankBranchName?: Maybe<Scalars['String']>;
  /**  银行账号户名  */
  bankAccountName?: Maybe<Scalars['String']>;
  /**  银行账号  */
  bankAccount?: Maybe<Scalars['String']>;
  /**  状态  */
  status: InvestorPaymentBankAccountStatusEnum;
  /**  今日收款金额  */
  todayShippingTotalAmount: Scalars['Float'];
};

export enum InvestorPaymentBankAccountStatusEnum {
  /** 启用 */
  Enabled = 'ENABLED',
  /** 关闭 */
  Disabled = 'DISABLED',
  /** 停用 */
  DisabledByAbnormal = 'DISABLED_BY_ABNORMAL',
  /** 删除 */
  Deleted = 'DELETED'
}

export enum InvestorPaymentTypeEnum {
  /** 银行卡 */
  BankAccount = 'BANK_ACCOUNT',
  /** 支付宝 */
  Alipay = 'ALIPAY',
  /** 支付宝扫码 */
  AlipayScan = 'ALIPAY_SCAN',
  /** 微信扫码 */
  WechatScan = 'WECHAT_SCAN'
}

export type InvestorPurchaseOrderHistoryInput = {
  /**  货币类型  */
  currencyType?: Maybe<CurrencyTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  交易类型  */
  paymentType?: Maybe<PurchasePaymentTypeEnum>;
  /**  订单状态  */
  orderStatus?: Maybe<ClientPurchaseOrderStatusEnum>;
  /**  交易所订单状态  */
  exchangeWebsiteOrderStatus?: Maybe<ExchangeWebsiteOrderStatusEnum>;
  /**  订单建立开始时间  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  订单建立结束时间  */
  endTime?: Maybe<Scalars['DateTime']>;
};

export type InvestorPurchaseOrderInfo = {
  /**  订单编号  */
  _id: Scalars['ID'];
  /**  交易方式  */
  paymentType: PurchasePaymentTypeEnum;
  /**  金额  */
  amount: Scalars['Float'];
  /**  投资者编号  */
  investorId?: Maybe<Scalars['Int']>;
  /**  订单建立时间  */
  createdAt: Scalars['DateTime'];
  /**  订单过期时间  */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /**  订单状态  */
  status: ClientPurchaseOrderStatusEnum;
  /**  银行名称  */
  bankName: Scalars['String'];
  /**  银行账号  */
  bankAccount: Scalars['String'];
  /**  银行账号户名  */
  bankAccountName: Scalars['String'];
  /**  商品资讯  */
  commodity?: Maybe<Commodity>;
  /**  运费  */
  shippingFee?: Maybe<Scalars['Float']>;
  /**  投资者红利  */
  investorBonus?: Maybe<Scalars['Float']>;
  /**  上传档案数量  */
  uploadFileCount?: Maybe<Scalars['Int']>;
  /**  支付宝账号  */
  alipayAccount: Scalars['String'];
  /**  支付宝 Qrcode 图片  */
  alipayQrcodeImage: Scalars['String'];
  /**  支付宝姓名  */
  alipayName: Scalars['String'];
};

export type InvestorPurchaseOrderInfoInput = {
  /**  订单编号  */
  orderUuid: Scalars['String'];
};

export type InvestorPurchaseOrdersInput = {
  /**  交易方式  */
  paymentTypes: Array<PurchasePaymentTypeEnum>;
};

export enum InvestorQuotaChangeTypeEnum {
  /** 卖币接单 */
  Shipping = 'SHIPPING',
  /** 卖币失败 */
  ShippingFailure = 'SHIPPING_FAILURE',
  /** 买币成功 */
  Purchase = 'PURCHASE',
  /** 提现 */
  Withdraw = 'WITHDRAW',
  /** 提现失败 */
  WithdrawFailure = 'WITHDRAW_FAILURE',
  /** 后台充值 */
  RechargeByAdmin = 'RECHARGE_BY_ADMIN',
  /** 买币成功调账 */
  PurchaseOrderPositiveOffset = 'PURCHASE_ORDER_POSITIVE_OFFSET',
  /** 买币失败调账 */
  PurchaseOrderNegativeOffset = 'PURCHASE_ORDER_NEGATIVE_OFFSET',
  /** 充值 */
  Recharge = 'RECHARGE',
  /** 冻结 */
  Freeze = 'FREEZE',
  /** 解冻 */
  Unfreeze = 'UNFREEZE',
  /** 红利转额度加款 */
  ConvertBonusToQuota = 'CONVERT_BONUS_TO_QUOTA',
  /** 额度转账加款 */
  AddByTransfer = 'ADD_BY_TRANSFER',
  /** 额度转账扣款 */
  SubByTransfer = 'SUB_BY_TRANSFER',
  /** 卖币失败调账 */
  ShippingOrderNegativeOffset = 'SHIPPING_ORDER_NEGATIVE_OFFSET',
  /** 卖币补单 */
  ReplicatedShippingOrder = 'REPLICATED_SHIPPING_ORDER',
  /** 卖币补单失败 */
  ReplicatedShippingOrderFailure = 'REPLICATED_SHIPPING_ORDER_FAILURE',
  /** 充值失败 */
  RechargeFailure = 'RECHARGE_FAILURE',
  /** 货币换汇 */
  CurrencyExchange = 'CURRENCY_EXCHANGE',
  /** 收款汇差调整 */
  ShippingBonus = 'SHIPPING_BONUS',
  /** 付款汇差调整 */
  PurchaseBonus = 'PURCHASE_BONUS',
  /** 收款上级汇差调整 */
  ShippingSuperiorBonus = 'SHIPPING_SUPERIOR_BONUS',
  /** 付款上级汇差调整 */
  PurchaseSuperiorBonus = 'PURCHASE_SUPERIOR_BONUS',
  /** 余额调整 */
  AdjustBalance = 'ADJUST_BALANCE',
  /** 公司损失 */
  CompanyLoss = 'COMPANY_LOSS',
  /** 操作错误 */
  WrongOperation = 'WRONG_OPERATION',
  /** 余额转移 */
  TransferBalance = 'TRANSFER_BALANCE',
  /** 其他 */
  Other = 'OTHER'
}

export type InvestorQuotaHistoryInput = {
  /**  异动类型  */
  changeType?: Maybe<InvestorQuotaChangeTypeEnum>;
  /**  货币类型  */
  currencyType?: Maybe<CurrencyTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  开始时间  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  结束时间  */
  endTime?: Maybe<Scalars['DateTime']>;
};

export type InvestorQuotaRecord = {
  /**  异动原因  */
  changeType?: Maybe<InvestorQuotaChangeTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  币种  */
  currencyType?: Maybe<CurrencyTypeEnum>;
  /**  修改前  */
  changeBefore?: Maybe<Scalars['Float']>;
  /**  修改数量  */
  changeAmount?: Maybe<Scalars['Float']>;
  /**  修改后  */
  changeAfter?: Maybe<Scalars['Float']>;
  /**  时间  */
  createdAt?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of InvestorQuotaRecord items. */
export type InvestorQuotaRecordPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorQuotaRecord items. */
  data: Array<InvestorQuotaRecord>;
};

export type InvestorReportHeartbeatResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type InvestorRootBonusSystemGroup = {
  /**  网易红利费率  */
  netEasePercentReward?: Maybe<InvestorRootBonusSystemGroupNetEasePercentReward>;
  /**  YOHO 红利费率  */
  yohoPercentReward?: Maybe<InvestorRootBonusSystemGroupYohoPercentReward>;
  /**  支付宝(代收)红利费率  */
  alipayPercentReward?: Maybe<InvestorRootBonusSystemGroupAlipayPercentReward>;
  /**  支付宝扫码(代收)红利费率  */
  alipayScanPercentReward?: Maybe<InvestorRootBonusSystemGroupAlipayScanPercentReward>;
  /**  微信扫码(代收)红利费率  */
  wechatScanPercentReward?: Maybe<InvestorRootBonusSystemGroupWechatScanPercentReward>;
  /**  支付宝(代付)红利费率  */
  purchaseAlipayPercentReward?: Maybe<InvestorRootBonusSystemGroupPurchaseAlipayPercentReward>;
};

export type InvestorRootBonusSystemGroupAlipayPercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorRootBonusSystemGroupAlipayScanPercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorRootBonusSystemGroupNetEasePercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorRootBonusSystemGroupPurchaseAlipayPercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorRootBonusSystemGroupWechatScanPercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorRootBonusSystemGroupYohoPercentReward = {
  /**  创建下级可用费率  */
  childPercentList: Array<Scalars['String']>;
};

export type InvestorSettings = {
  /**  是否开启额度转账  */
  transferQuotaEnabled: Scalars['Boolean'];
  /**  是否允许红利转额度  */
  convertBonusToQuotaEnabled: Scalars['Boolean'];
  /**  是否显示交易中心  */
  marketDisplayed: Scalars['Boolean'];
  /**  是否能进货  */
  purchaseEnabled: Scalars['Boolean'];
  /**  是否能注册下级  */
  createChildEnabled: Scalars['Boolean'];
};

export type InvestorShippingOrderHistoryInput = {
  /**  货币类型  */
  currencyType?: Maybe<CurrencyTypeEnum>;
  /**  订单编号  */
  orderUuid?: Maybe<Scalars['String']>;
  /**  交易类型  */
  paymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  订单状态  */
  orderStatus?: Maybe<ClientShippingOrderStatusEnum>;
  /**  交易所订单状态  */
  exchangeWebsiteOrderStatus?: Maybe<ExchangeWebsiteOrderStatusEnum>;
  /**  订单建立开始时间  */
  startTime?: Maybe<Scalars['DateTime']>;
  /**  订单建立结束时间  */
  endTime?: Maybe<Scalars['DateTime']>;
};

export type InvestorShippingOrderInfo = {
  /**  订单编号  */
  _id: Scalars['ID'];
  /**  金额  */
  amount: Scalars['Float'];
  /**  交易方式  */
  paymentType: ShippingPaymentTypeEnum;
  /**  投资者编号  */
  investorId?: Maybe<Scalars['Int']>;
  /**  订单状态  */
  status?: Maybe<ClientShippingOrderStatusEnum>;
  /**  商品资讯  */
  commodity?: Maybe<Commodity>;
  /**  运费  */
  shippingFee?: Maybe<Scalars['Float']>;
  /**  建立时间  */
  createdAt?: Maybe<Scalars['DateTime']>;
  /**  投资者红利  */
  investorBonus?: Maybe<Scalars['Float']>;
  /**  订单用户姓名  */
  orderUserRealName: Scalars['String'];
  /**  USDT 货币汇率资讯  */
  usdtCurrencyExchangeInfo: UsdtCurrencyExchangeInfo;
  /**  收款者银行资讯  */
  payeePaymentAccount?: Maybe<PayeePaymentAccount>;
  /**  收款者支付宝资讯  */
  payeeAlipayPaymentAccount?: Maybe<PayeeAlipayPaymentAccount>;
  /**  收款者支付宝扫码资讯  */
  payeeAlipayScanAccount?: Maybe<PayeeAlipayScanAccount>;
  /**  收款者微信扫码资讯  */
  payeeWechatScanAccount?: Maybe<PayeeWechatScanAccount>;
};

export type InvestorShippingOrderInfoInput = {
  /**  订单编号  */
  orderUuid: Scalars['String'];
};

export type InvestorShippingOrdersInput = {
  /**  交易方式  */
  paymentTypes: Array<ShippingPaymentTypeEnum>;
};

export type InvestorSignupCode = {
  /**  编号  */
  id: Scalars['ID'];
  /**  网易红利比例(%)  */
  netEasePercent: Scalars['Float'];
  /**  YOHO 红利比例(%)  */
  yohoPercent: Scalars['Float'];
  /**  支付宝红利比例(%)  */
  alipayPercent: Scalars['Float'];
  /**  支付宝扫码(代付)红利比例(%)  */
  purchaseAlipayPercent: Scalars['Float'];
  /**  支付宝扫码红利比例(%)  */
  alipayScanPercent: Scalars['Float'];
  /**  微信扫码红利比例(%)  */
  wechatScanPercent: Scalars['Float'];
  /**  注册码  */
  code: Scalars['String'];
  /**  注册人数  */
  signupCount?: Maybe<Scalars['Int']>;
  /**  备注  */
  remark: Scalars['String'];
};

/** A paginated list of InvestorSignupCode items. */
export type InvestorSignupCodePaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorSignupCode items. */
  data: Array<InvestorSignupCode>;
};

export type InvestorTeam = {
  /**  团队下级单一投资者  */
  childInvestor: ChildInvestor;
  /**  单一直属下级团队营利报表  */
  teamMemberProfitReport: InvestorTransactionStatistic;
  /**  个人营利报表  */
  personalProfitReport: InvestorTransactionStatistic;
  /**  团队下级列表  */
  teamMembers?: Maybe<InvestorTeamMembersPaginator>;
  /**  全部直属下级团队营利报表  */
  teamMemberProfitReports?: Maybe<InvestorTransactionStatisticPaginator>;
  /**  注册码列表  */
  signupCodes?: Maybe<InvestorSignupCodePaginator>;
};


export type InvestorTeam_ChildInvestorArgs = {
  input: ChildInvestorInput;
};


export type InvestorTeam_TeamMemberProfitReportArgs = {
  input: TeamMemberProfitReportInput;
};


export type InvestorTeam_PersonalProfitReportArgs = {
  input: PersonalProfitReportInput;
};


export type InvestorTeam_TeamMembersArgs = {
  input?: Maybe<InvestorTeamMembersInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type InvestorTeam_TeamMemberProfitReportsArgs = {
  input: TeamMemberProfitReportsInput;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type InvestorTeam_SignupCodesArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type InvestorTeamMembers = {
  /**  账号  */
  account: Scalars['String'];
  /**  团队人数额度  */
  teamMemberQuota: Scalars['Int'];
  /**  网易红利比率(%)  */
  netEasePercent: Scalars['Float'];
  /**  YOHO 红利比率(%)  */
  yohoPercent: Scalars['Float'];
  /**  支付宝红利比率(%)  */
  alipayPercent: Scalars['Float'];
  /**  支付宝扫码红利比率(%)  */
  alipayScanPercent: Scalars['Float'];
  /**  微信扫码红利比率(%)  */
  wechatScanPercent: Scalars['Float'];
};

export type InvestorTeamMembersInput = {
  /**  投资者账号  */
  account?: Maybe<Scalars['String']>;
};

/** A paginated list of InvestorTeamMembers items. */
export type InvestorTeamMembersPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorTeamMembers items. */
  data: Array<InvestorTeamMembers>;
};

export type InvestorTeamMutation = {
  /**  创建下级投资者  */
  createChildInvestor?: Maybe<CreateChildInvestorResult>;
  /**  编辑下级投资者  */
  editChildInvestor?: Maybe<EditChildInvestorResult>;
  /**  创建注册码  */
  createSignupCode?: Maybe<CreateSignupCodeResult>;
  /**  删除注册码  */
  deleteSignupCode?: Maybe<DeleteSignupCodeResult>;
};


export type InvestorTeamMutation_CreateChildInvestorArgs = {
  input: CreateChildInvestorInput;
};


export type InvestorTeamMutation_EditChildInvestorArgs = {
  input: EditChildInvestorInput;
};


export type InvestorTeamMutation_CreateSignupCodeArgs = {
  input: CreateSignupCodeInput;
};


export type InvestorTeamMutation_DeleteSignupCodeArgs = {
  input: DeleteSignupCodeInput;
};

export type InvestorTransactionStatistic = {
  /**  账号  */
  account: Scalars['String'];
  /**  进货交易量  */
  purchaseOrderAmount: Scalars['Float'];
  /**  进货红利  */
  purchaseBonus: Scalars['Float'];
  /**  出货交易量  */
  shippingOrderAmount: Scalars['Float'];
  /**  出货分红  */
  shippingBonus: Scalars['Float'];
  /**  出货费率  */
  netEasePercent?: Maybe<Scalars['Float']>;
  /**  进货费率  */
  yohoPercent?: Maybe<Scalars['Float']>;
  /**  团队人数  */
  teamMemberQuota?: Maybe<Scalars['Int']>;
};

/** A paginated list of InvestorTransactionStatistic items. */
export type InvestorTransactionStatisticPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of InvestorTransactionStatistic items. */
  data: Array<InvestorTransactionStatistic>;
};

export type InvestorWallet = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  额度  */
  quota: Scalars['Float'];
  /**  红利  */
  bonus: Scalars['Float'];
  /**  出货中数量  */
  shippingAmount: Scalars['Float'];
  /**  进货中数量  */
  purchasingAmount: Scalars['Float'];
  /**  进货最大订单金额  */
  purchaseOrderAmountMax: Scalars['Float'];
  /**  进货最小订单金额  */
  purchaseOrderAmountMin: Scalars['Float'];
  /**  剩余业绩  */
  achievementDeficit: Scalars['Float'];
  /**  冻结额度  */
  frozenQuota: Scalars['Float'];
  /**  冻结红利  */
  frozenBonus: Scalars['Float'];
};

export type InvestorWalletsInput = {
  /**  欲查询的币种  */
  currencyTypes: Array<CurrencyTypeEnum>;
};

export type InvestorWechatScanAccount = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  支付方式  */
  paymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  微信姓名  */
  alipayName?: Maybe<Scalars['String']>;
  /**  微信扫码图片 URL  */
  qrcodeUrl?: Maybe<Scalars['String']>;
  /**  状态  */
  status: InvestorWechatScanAccountStatusEnum;
  /**  今日收款金额  */
  todayShippingTotalAmount: Scalars['Float'];
};

export enum InvestorWechatScanAccountStatusEnum {
  /** 启用 */
  Enabled = 'ENABLED',
  /** 关闭 */
  Disabled = 'DISABLED',
  /** 停用 */
  DisabledByAbnormal = 'DISABLED_BY_ABNORMAL',
  /** 删除 */
  Deleted = 'DELETED'
}

export type InvestorWithdrawBankAccount = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  银行名称  */
  bankName?: Maybe<Scalars['String']>;
  /**  银行分行名称  */
  bankBranchName?: Maybe<Scalars['String']>;
  /**  银行账号户名  */
  bankAccountName?: Maybe<Scalars['String']>;
  /**  银行账号  */
  bankAccount?: Maybe<Scalars['String']>;
};

export type InvestorWithdrawWalletAddress = {
  /**  流水号  */
  id: Scalars['ID'];
  /**  钱包地址  */
  address?: Maybe<Scalars['String']>;
};

export type KeyValueObject = {
  /**  键  */
  k?: Maybe<Scalars['String']>;
  /**  值  */
  v?: Maybe<Scalars['String']>;
};

export type LevelGroup = {
  /**  出货同时分配订单数量  */
  shippingOrderConcurrentMax: Scalars['Int'];
  /**  进货订单上传图片开关 关闭时必须上传影片  */
  purchaseOrderUploadImageEnabled: Scalars['Boolean'];
};

export type LevelGroupQuota = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  额度保留金额  */
  retainedQuota: Scalars['Float'];
  /**  出货每日限额  */
  shippingDailyQuota: Scalars['Float'];
  /**  出货最大订单金额  */
  shippingOrderAmountMax: Scalars['Float'];
  /**  出货最小订单金额  */
  shippingOrderAmountMin: Scalars['Float'];
  /**  进货每日限额  */
  purchaseDailyQuota: Scalars['Float'];
  /**  进货最大订单金额  */
  purchaseOrderAmountMax: Scalars['Float'];
  /**  进货最小订单金额  */
  purchaseOrderAmountMin: Scalars['Float'];
};

/**  登入用 Input  */
export type LoginInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  密码  */
  password: Scalars['String'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type LoginResult = {
  /**  登入令牌  */
  token: Scalars['String'];
  /**  目前登入的投资者  */
  investor: Investor;
};

export type LogoutResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type MarkMessageAsReadInput = {
  /**  讯息编号  */
  messageIds: Array<Scalars['ID']>;
};

export type MarkMessageAsReadResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type MarketInvestorInfo = {
  /**  投资者  */
  account?: Maybe<Scalars['String']>;
  /**  USDT 额度  */
  usdtQuota?: Maybe<Scalars['Float']>;
};

/** A paginated list of MarketInvestorInfo items. */
export type MarketInvestorInfoPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of MarketInvestorInfo items. */
  data: Array<MarketInvestorInfo>;
};

/**  任务  */
export type Mission = {
  /**  名称  */
  name?: Maybe<Scalars['String']>;
  /**  任务 icon  */
  missionLogoUrl?: Maybe<Scalars['String']>;
  /**  任务排序  */
  order: Scalars['Int'];
  /**  订单类型  */
  orderType: OrderTypeEnum;
  /**  出货交易方式  */
  shippingPaymentType?: Maybe<ShippingPaymentTypeEnum>;
  /**  进货交易方式  */
  purchasePaymentType?: Maybe<PurchasePaymentTypeEnum>;
};

export type MobileConfig = {
  /**  应用最新版号  */
  appNewestVersion: Scalars['String'];
  /**  应用最低支援版号  */
  appRequiredVersion: Scalars['String'];
  /**  应用最新版本下载位置  */
  appNewestDownload: Scalars['String'];
  /**  资源包最新版本号  */
  assetNewestVersion: Scalars['String'];
  /**  资源包最低版本号  */
  assetRequiredVersion: Scalars['String'];
  /**  资源包最新版本下载位置  */
  assetNewestDownload: Scalars['String'];
  /**  资源包最新版本下载位置v2  */
  assetNewestHashtable: Scalars['String'];
  /**  是否启用 asset 机制  */
  assetEnabled: Scalars['Boolean'];
};

export type Mutation = {
  /**  登入  */
  login?: Maybe<LoginResult>;
  /**  投资者相关 Mutation  */
  investor?: Maybe<InvestorMutation>;
  /**  用注册码创建账号  */
  signupByCode?: Maybe<SignupByCodeResult>;
  /**  创建交易站点账号  */
  signupInExchangeWebsite?: Maybe<SignupInExchangeWebsiteResult>;
};


export type Mutation_LoginArgs = {
  input: LoginInput;
};


export type Mutation_SignupByCodeArgs = {
  input: SignupByCodeInput;
};


export type Mutation_SignupInExchangeWebsiteArgs = {
  input: SignupInExchangeWebsiteInput;
};

export type Options = {
  /**  银行选项  */
  bankOptions: Array<Bank>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export enum OrderTypeEnum {
  /** 出货订单 */
  ShippingOrder = 'SHIPPING_ORDER',
  /** 进货订单 */
  PurchaseOrder = 'PURCHASE_ORDER'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type PayeeAlipayPaymentAccount = {
  /**  支付宝账号  */
  alipayAccount?: Maybe<Scalars['String']>;
  /**  支付宝姓氏  */
  alipayName?: Maybe<Scalars['String']>;
};

export type PayeeAlipayScanAccount = {
  /**  支付宝姓名  */
  alipayName: Scalars['String'];
  /**  支付宝 UID  */
  alipayUid?: Maybe<Scalars['String']>;
  /**  QRCode 图片  */
  qrcodeImage?: Maybe<Scalars['String']>;
};

export type PayeePaymentAccount = {
  /**  银行编号  */
  bankId?: Maybe<Scalars['Int']>;
  /**  银行账号  */
  bankAccount?: Maybe<Scalars['String']>;
  /**  银行户名  */
  bankAccountName?: Maybe<Scalars['String']>;
};

export type PayeeWechatScanAccount = {
  /**  微信姓名  */
  wechatName: Scalars['String'];
  /**  QRCode 图片  */
  qrcodeImage?: Maybe<Scalars['String']>;
};

export type PersonalProfitReportInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  起始时间  */
  startTime: Scalars['DateTime'];
  /**  结束时间  */
  endTime: Scalars['DateTime'];
};

export type PurchaseOrder = {
  /**  订单编号  */
  _id: Scalars['ID'];
  /**  金额  */
  amount: Scalars['Float'];
  /**  交易方式  */
  paymentType: PurchasePaymentTypeEnum;
  /**  投资者编号  */
  investorId?: Maybe<Scalars['Int']>;
  /**  订单状态  */
  status?: Maybe<ClientPurchaseOrderStatusEnum>;
  /**  商品资讯  */
  commodity?: Maybe<Commodity>;
  /**  运费  */
  shippingFee?: Maybe<Scalars['Float']>;
  /**  建立时间  */
  createdAt?: Maybe<Scalars['DateTime']>;
  /**  投资者红利  */
  investorBonus?: Maybe<Scalars['Float']>;
  /**  汇率  */
  exchangeRate?: Maybe<Scalars['Float']>;
  /**  订单金额(USDT)  */
  orderAmountInUsdt?: Maybe<Scalars['Float']>;
  /**  付款者银行  */
  payerBankId?: Maybe<Scalars['Int']>;
  /**  付款者银行户名  */
  payerBankAccountName?: Maybe<Scalars['String']>;
  /**  付款者银行帐号  */
  payerBankAccount?: Maybe<Scalars['String']>;
  /**  付款者支行  */
  payerBankBranchName?: Maybe<Scalars['String']>;
  /**  收款者银行  */
  payeeBankId?: Maybe<Scalars['Int']>;
  /**  收款者银行户名  */
  payeeBankAccountName?: Maybe<Scalars['String']>;
  /**  收款者银行帐号  */
  payeeBankAccount?: Maybe<Scalars['String']>;
  /**  付款者支付宝账号  */
  payerAlipayAccount?: Maybe<Scalars['String']>;
  /**  付款者支付宝姓名  */
  payerAlipayName?: Maybe<Scalars['String']>;
  /**  收款者支付宝账号  */
  payeeAlipayAccount?: Maybe<Scalars['String']>;
  /**  收款者支付宝 Qrcode 图片  */
  payeeAlipayQrcodeImage?: Maybe<Scalars['String']>;
  /**  收款者支付宝姓名  */
  payeeAlipayName?: Maybe<Scalars['String']>;
};

/** A paginated list of PurchaseOrder items. */
export type PurchaseOrderPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PurchaseOrder items. */
  data: Array<PurchaseOrder>;
};

export enum PurchasePaymentTypeEnum {
  /** 有货 */
  Yoho = 'YOHO',
  /** USDT_WITHDRAW */
  UsdtWithdraw = 'USDT_WITHDRAW',
  /** 支付宝 */
  AlipayH5Purchase = 'ALIPAY_H5_PURCHASE'
}

export type Query = {
  /**  目前登入者资讯  */
  me?: Maybe<Investor>;
  /**  任务列表资讯  */
  missions: Array<Mission>;
  /**  设定档相关资讯  */
  config?: Maybe<Config>;
  /**  选单相关资讯  */
  options?: Maybe<Options>;
  /**  列举  */
  enums?: Maybe<Enums>;
  /**  站点资讯  */
  website?: Maybe<Website>;
  /**  公告  */
  announcements?: Maybe<AnnouncementPaginator>;
  /**  广告  */
  banner?: Maybe<BannerPaginator>;
};


export type Query_AnnouncementsArgs = {
  input?: Maybe<AnnouncementsInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type Query_BannerArgs = {
  input?: Maybe<BannersInput>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QuotaSetting = {
  /**  栏位显示名称  */
  displayName: Scalars['String'];
  /**  栏位名称  */
  fieldName: Scalars['String'];
  /**  值  */
  value?: Maybe<Scalars['Int']>;
};

/**  领取进货单  */
export type ReceivePurchaseOrderInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['String'];
  /**  忽略与伺服器汇率差异，强制领取  */
  forceReceive?: Maybe<Scalars['Boolean']>;
  /**  汇率  */
  exchangeRate?: Maybe<Scalars['Float']>;
};

export type ReceivePurchaseOrderResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
  /**  用户端与伺服器端汇率是否不同？ true=不同,false=相同  */
  isRateDifferent?: Maybe<Scalars['Boolean']>;
  /**  即时汇率  */
  currentExchangeRate?: Maybe<Scalars['Float']>;
};

export type RechargeInvestorQuotaInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  金额  */
  amount: Scalars['Float'];
  /**  付款人姓名  */
  userRealName: Scalars['String'];
};

export type RechargeInvestorQuotaResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
  /**  银行名称  */
  bankName: Scalars['String'];
  /**  银行账号  */
  bankAccount: Scalars['String'];
  /**  银行账号户名  */
  bankAccountName: Scalars['String'];
  /**  银行分行名称  */
  bankBranchName?: Maybe<Scalars['String']>;
  /**  附言  */
  remark?: Maybe<Scalars['String']>;
};

export type RechargeQuotaByUsdtInput = {
  /**  金额  */
  amount: Scalars['Float'];
  /**  付款人钱包地址  */
  userAddress: Scalars['String'];
};

export type RechargeQuotaByUsdtResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
  /**  钱包地址  */
  address: Scalars['String'];
  /**  充值单 UUID  */
  orderUuid: Scalars['String'];
  /**  金额  */
  amount: Scalars['Float'];
  /**  附言  */
  remark?: Maybe<Scalars['String']>;
};

/**  出货单申请异常回报  */
export type ReportShippingOrderProblemInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['ID'];
  /**  异常类型  */
  problemType: ShippingOrderProblemTypeEnum;
  /**  收据图档  */
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  /**  异常备注  */
  remark?: Maybe<Scalars['String']>;
};

export type ReportShippingOrderProblemResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SendAuthCodeInput = {
  /**  手机号码  */
  mobileNumber?: Maybe<Scalars['String']>;
};

export type SendAuthCodeResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetInvestorLoginPasswordInput = {
  /**  旧密码  */
  oldPassword: Scalars['String'];
  /**  新密码  */
  newPassword: Scalars['String'];
  /**  新密码确认  */
  newPasswordConfirmation: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type SetInvestorLoginPasswordResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetInvestorMobileNumberInput = {
  /**  手机号码  */
  mobileNumber: Scalars['String'];
};

export type SetInvestorMobileNumberResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetInvestorNameInput = {
  /**  名称  */
  name: Scalars['String'];
};

export type SetInvestorNameResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetInvestorQqAccountInput = {
  /**  账号  */
  account: Scalars['String'];
};

export type SetInvestorQqAccountResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetInvestorSecurityPasswordInput = {
  /**  旧密码  */
  oldPassword?: Maybe<Scalars['String']>;
  /**  新密码  */
  newPassword: Scalars['String'];
  /**  新密码确认  */
  newPasswordConfirmation: Scalars['String'];
  /**  安全机制验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type SetInvestorSecurityPasswordResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  设定是否接单  */
export type SetOrderEnabledInput = {
  /**  欲设定的订单类型  */
  orderType: OrderTypeEnum;
  /**  是否开启  */
  enabled: Scalars['Boolean'];
  /**  欲开启的支付方式 (开启时检查)  */
  investorPaymentTypes: Array<InvestorPaymentTypeEnum>;
};

export type SetOrderEnabledResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SetPurchaseOrderAmountLimitInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  最大订单金额  */
  amountMax: Scalars['Float'];
  /**  最小订单金额  */
  amountMin: Scalars['Float'];
};

export type SetPurchaseOrderAmountLimitResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type ShippingOrder = {
  /**  订单编号  */
  _id: Scalars['ID'];
  /**  金额  */
  amount: Scalars['Float'];
  /**  交易方式  */
  paymentType: ShippingPaymentTypeEnum;
  /**  投资者编号  */
  investorId?: Maybe<Scalars['Int']>;
  /**  订单状态  */
  status?: Maybe<ClientShippingOrderStatusEnum>;
  /**  商品资讯  */
  commodity?: Maybe<Commodity>;
  /**  运费  */
  shippingFee?: Maybe<Scalars['Float']>;
  /**  建立时间  */
  createdAt?: Maybe<Scalars['DateTime']>;
  /**  投资者红利  */
  investorBonus?: Maybe<Scalars['Float']>;
  /**  汇率  */
  exchangeRate?: Maybe<Scalars['Float']>;
  /**  订单金额(USDT)  */
  orderAmountInUsdt?: Maybe<Scalars['Float']>;
  /**  付款姓名  */
  payerName?: Maybe<Scalars['String']>;
  /**  收款者银行  */
  payeeBankId?: Maybe<Scalars['Int']>;
  /**  收款者银行户名  */
  payeeBankAccountName?: Maybe<Scalars['String']>;
  /**  收款者银行帐号  */
  payeeBankAccount?: Maybe<Scalars['String']>;
  /**  收款者支行  */
  payeeBankBranchName?: Maybe<Scalars['String']>;
  /**  收款者支付宝帐号  */
  payeeAlipayAccount?: Maybe<Scalars['String']>;
  /**  收款者支付宝姓名  */
  payeeAlipayName?: Maybe<Scalars['String']>;
  /**  收款者支付宝 UID  */
  payeeAlipayUid?: Maybe<Scalars['String']>;
  /**  收款者支付宝or微信扫码图片  */
  payeeQrcodeImage?: Maybe<Scalars['String']>;
  /**  收款者支付宝扫码姓名  */
  payeeAlipayScanName?: Maybe<Scalars['String']>;
  /**  收款者微信扫码姓名  */
  payeeWechatScanName?: Maybe<Scalars['String']>;
};

/** A paginated list of ShippingOrder items. */
export type ShippingOrderPaginator = {
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ShippingOrder items. */
  data: Array<ShippingOrder>;
};

export enum ShippingOrderProblemTypeEnum {
  /** 无 */
  None = 'NONE',
  /** 无主单 */
  NoMerchant = 'NO_MERCHANT',
  /** 姓名错误 */
  WrongName = 'WRONG_NAME',
  /** 金额错误 */
  WrongAmount = 'WRONG_AMOUNT',
  /** 支付方式错误 */
  WrongPaymentType = 'WRONG_PAYMENT_TYPE',
  /** 未收款 */
  Unpaid = 'UNPAID'
}

export type ShippingOrderUpdatedInput = {
  /**  订单编号  */
  orderUuid: Scalars['ID'];
};

export enum ShippingPaymentTypeEnum {
  /** 网易 */
  NetEase = 'NET_EASE',
  /** 淘宝 */
  Taobao = 'TAOBAO',
  /** 蘑菇 */
  Mogu = 'MOGU',
  /** 快捷 */
  Kuaijie = 'KUAIJIE',
  /** 支付宝扫码 */
  AlipayScan = 'ALIPAY_SCAN',
  /** 支付宝H5 */
  AlipayH5 = 'ALIPAY_H5',
  /** USDT */
  UsdtRecharge = 'USDT_RECHARGE',
  /** 支付宝原生 */
  AlipayNative = 'ALIPAY_NATIVE',
  /** 微信扫码 */
  WechatScan = 'WECHAT_SCAN'
}

export type SignupByCodeInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  密码  */
  password: Scalars['String'];
  /**  注册码  */
  code: Scalars['String'];
};

export type SignupByCodeResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type SignupInExchangeWebsiteInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  密码  */
  password: Scalars['String'];
  /**  上级账号  */
  parentAccount: Scalars['String'];
};

export type SignupInExchangeWebsiteResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export type SmsConfigResult = {
  /**  重新发送等待时间  */
  resendSeconds: Scalars['Int'];
  /**  验证码过期时间  */
  expireSeconds: Scalars['Int'];
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Subscription = {
  /**  出货单更动  */
  shippingOrderUpdated?: Maybe<ShippingOrder>;
  /**  出货单建立  */
  shippingOrderCreated?: Maybe<ShippingOrder>;
  /**  进货单建立  */
  purchaseOrderCreated?: Maybe<PurchaseOrder>;
  /**  投資者訊息建立  */
  investorMessageCreated?: Maybe<InvestorMessage>;
};


export type Subscription_ShippingOrderUpdatedArgs = {
  input: ShippingOrderUpdatedInput;
};

export type TeamMemberProfitReportInput = {
  /**  投资者账号  */
  account: Scalars['String'];
  /**  起始时间  */
  startTime: Scalars['DateTime'];
  /**  结束时间  */
  endTime: Scalars['DateTime'];
};

export type TeamMemberProfitReportsInput = {
  /**  账号  */
  account: Scalars['String'];
  /**  起始时间  */
  startTime: Scalars['DateTime'];
  /**  结束时间  */
  endTime: Scalars['DateTime'];
};

export type TransferQuotaToOtherInvestorInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  数量  */
  amount: Scalars['Float'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
  /**  转出账号  */
  account: Scalars['String'];
};

export type TransferQuotaToOtherInvestorResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}


/**  进货单上传档案  */
export type UploadPurchaseOrderFileInput = {
  /**  订单 UUID  */
  orderUuid: Scalars['String'];
  /**  收据图片或影片档案  */
  file: Scalars['Upload'];
};

export type UploadPurchaseOrderFileResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type UsdtCurrencyExchangeInfo = {
  /**  汇率  */
  exchangeRate?: Maybe<Scalars['Float']>;
  /**  金额  */
  amount?: Maybe<Scalars['Float']>;
};

export type Website = {
  /**  苹果应用程式下载连结  */
  iosAppDownloadUrl?: Maybe<Scalars['String']>;
  /**  安卓应用程式下载连结  */
  androidAppDownloadUrl?: Maybe<Scalars['String']>;
  /**  客户服务连结  */
  customerServiceUrl?: Maybe<Scalars['String']>;
  /**  可用收款方式  */
  investorAvailableShippingPaymentTypes: Array<InvestorPaymentTypeEnum>;
  /**  可用付款方式  */
  investorAvailablePurchasePaymentTypes: Array<InvestorPaymentTypeEnum>;
};

export type WithdrawInvestorBonusInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  金额  */
  amount: Scalars['Float'];
  /**  收款账号  */
  investorPaymentAccountId: Scalars['String'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type WithdrawInvestorBonusResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

export type WithdrawInvestorQuotaInput = {
  /**  币种  */
  currencyType: CurrencyTypeEnum;
  /**  金额  */
  amount: Scalars['Float'];
  /**  收款账号  */
  investorPaymentAccountId: Scalars['String'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type WithdrawInvestorQuotaResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};

/**  额度提现到钱包地址  */
export type WithdrawQuotaByUsdtInput = {
  /**  金额  */
  amount: Scalars['Float'];
  /**  收款账号  */
  investorPaymentAccountId: Scalars['String'];
  /**  验证码  */
  authCode?: Maybe<Scalars['String']>;
};

export type WithdrawQuotaByUsdtResult = {
  /**  是否成功  */
  success: Scalars['Boolean'];
};
