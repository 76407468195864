import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  Bank,
  DeleteInvestorAlipayPaymentAccountInput,
  DeleteInvestorAlipayScanAccountInput,
  DeleteInvestorPaymentAccountInput,
  DeleteInvestorWechatScanAccountInput,
  InvestorAlipayPaymentAccount,
  InvestorAlipayScanAccount,
  InvestorPaymentAccount,
  InvestorWechatScanAccount,
  InvestorWithdrawBankAccount,
  InvestorWithdrawWalletAddress,
  Query,
} from 'lib/src/types/schema';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ALIPAY_PAYMENT_ACCOUNTS_QUERY,
  ALIPAY_SCAN_ACCOUNTS_QUERY,
  BANK_OPTIONS_QUERY,
  DELETE_ALIPAY_PAYMENT_ACCOUNT_MUTATION,
  DELETE_ALIPAY_SCAN_ACCOUNT_MUTATION,
  DELETE_PAYMENT_ACCOUNT_MUTATION,
  DELETE_WECHAT_SCAN_ACCOUNT_MUTATION,
  PAYMENT_ACCOUNTS_QUERY,
  WECHAT_SCAN_ACCOUNTS_QUERY,
  WITHDRAW_ADDRESSES_QUERY,
  WITHDRAW_BANK_ACCOUNT_QUERY
} from '../helpers/api';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentAccountsQueryRef = this.apollo.watchQuery<Query>({
    query: PAYMENT_ACCOUNTS_QUERY
  });
  alipayPaymentAccountsQueryRef = this.apollo.watchQuery<Query>({
    query: ALIPAY_PAYMENT_ACCOUNTS_QUERY
  });
  alipayScanAccountsQueryRef = this.apollo.watchQuery<Query>({
    query: ALIPAY_SCAN_ACCOUNTS_QUERY
  });
  wechatScanAccountsQueryRef = this.apollo.watchQuery<Query>({
    query: WECHAT_SCAN_ACCOUNTS_QUERY
  });
  withdrawAccountsQueryRef = this.apollo.watchQuery<Query>({
    query: WITHDRAW_BANK_ACCOUNT_QUERY
  });
  withdrawAddressesQueryRef = this.apollo.watchQuery<Query>({
    query: WITHDRAW_ADDRESSES_QUERY
  });
  paymentAccounts: InvestorPaymentAccount[];
  alipayPaymentAccounts: InvestorAlipayPaymentAccount[];
  alipayScanAccounts: InvestorAlipayScanAccount[];
  wechatScanAccounts: InvestorWechatScanAccount[];
  withdrawAccounts: InvestorWithdrawBankAccount[];
  withdrawAddresses: InvestorWithdrawWalletAddress[];
  bankOptions: Bank[] = [];
  bankMap$ = new BehaviorSubject<Record<any, Bank>>({});

  constructor(
    private apollo: Apollo
  ) {
    this.paymentAccountsQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.paymentAccounts?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(paymentAccounts => {
      this.paymentAccounts = paymentAccounts;
    })

    this.alipayPaymentAccountsQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.alipayPaymentAccounts?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(alipayPaymentAccounts => {
      this.alipayPaymentAccounts = alipayPaymentAccounts;
    })

    this.alipayScanAccountsQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.alipayScanAccounts?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(alipayScanAccounts => {
      this.alipayScanAccounts = alipayScanAccounts;
    })

    this.wechatScanAccountsQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.wechatScanAccounts?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(wechatScanAccounts => {
      this.wechatScanAccounts = wechatScanAccounts;
    })

    this.withdrawAccountsQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.withdrawBankAccounts?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(withdrawAccounts => {
      this.withdrawAccounts = withdrawAccounts;
    })

    this.withdrawAddressesQueryRef.valueChanges.pipe(
      map(resp => resp.data.me?.withdrawWalletAddresses?.slice().sort(x => x ? -1 : 1)!)
    ).subscribe(withdrawAddresses => {
      this.withdrawAddresses = withdrawAddresses;
    })

    this.updateBankOptions();
  }

  updateBankCard() {
    this.paymentAccountsQueryRef.refetch();
  }

  updateAlipayCard() {
    this.alipayPaymentAccountsQueryRef.refetch();
  }

  updateUSDTAddresses() {
    this.withdrawAddressesQueryRef.refetch();
  }

  updateAlipayScanCard() {
    this.alipayScanAccountsQueryRef.refetch();
  }

  updateWechatScanCard() {
    this.wechatScanAccountsQueryRef.refetch();
  }

  updateBankOptions() {
    this.apollo.watchQuery<Query>({
      query: BANK_OPTIONS_QUERY
    }).valueChanges.subscribe(resp => {
      this.bankOptions = resp.data.options?.bankOptions!;
      this.bankMap$.next(this.bankOptions.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {} as Record<any, Bank>));
    });
  }

  deletePaymentAccount(paymentAccountId: string) {
    const input: DeleteInvestorPaymentAccountInput = {
      paymentAccountId
    }
    return this.apollo.mutate({
      mutation: DELETE_PAYMENT_ACCOUNT_MUTATION,
      variables: {input}
    });
  }

  deleteAlipayPaymentAccount(paymentAccountId: string) {
    const input: DeleteInvestorAlipayPaymentAccountInput = {
      paymentAccountId
    }
    return this.apollo.mutate({
      mutation: DELETE_ALIPAY_PAYMENT_ACCOUNT_MUTATION,
      variables: {input}
    });
  }

  deleteAlipayScanAccount(paymentAccountId: string) {
    const input: DeleteInvestorAlipayScanAccountInput = {
      paymentAccountId
    }
    return this.apollo.mutate({
      mutation: DELETE_ALIPAY_SCAN_ACCOUNT_MUTATION,
      variables: {input}
    });
  }

  deleteWechatScanAccount(paymentAccountId: string) {
    const input: DeleteInvestorWechatScanAccountInput = {
      paymentAccountId
    }
    return this.apollo.mutate({
      mutation: DELETE_WECHAT_SCAN_ACCOUNT_MUTATION,
      variables: {input}
    });
  }
}
